import React, { useContext, useRef } from 'react';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';

export default function LogoBanner() {
  const { getStoreDetails } = useContext(AuthContext);
  const storeData = useRef(getStoreDetails()).current;

  const name = _get(storeData, 'name', '');
  const logoUrl = _get(storeData, 'logo', '');

  return (
    <div className="qrShippingLabel__logoBanner">
      {logoUrl ? <img src={logoUrl} alt={name} /> : <h2>{name}</h2>}
    </div>
  );
}

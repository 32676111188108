import { isPepper } from 'utils/retailer';

import easyExchange from 'images/easy-exchange.svg';
import fasterRefunds from 'images/faster-refunds.svg';
import realtimeTracking from 'images/realtime-tracking.svg';

const benefits = [
  { img: fasterRefunds, label: 'FASTER REFUNDS' },
  { img: realtimeTracking, label: 'REAL-TIME TRACKING' }
];

const offeredBenefits = (fixedBenefits => {
  if (isPepper()) return fixedBenefits;
  return [{ img: easyExchange, label: 'EASY EXCHANGES' }, ...fixedBenefits];
})(benefits);

export default offeredBenefits;

import React from 'react';
import { Helmet } from 'react-helmet';
import { connectStoreDetails } from 'utils/connectors/AppConnectors';
import isObjectEmpty from 'lodash/isEmpty';

function TitleMetaTag({ name, getStoreDetails }) {
  const storeDetails = getStoreDetails();
  if (!name || isObjectEmpty(storeDetails)) {
    return null;
  }
  return (
    <Helmet>
      <title>
        {name} | {storeDetails.name} Support
      </title>
    </Helmet>
  );
}

export default connectStoreDetails(TitleMetaTag);

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connectLogout } from 'utils/connectors/AppConnectors';
import { logEvent } from 'utils/analytics';
class Logout extends Component {
  componentDidMount() {
    logEvent('Logout');
    this.props.logout();
  }
  render() {
    return <Redirect to="/login?from=logout" />;
  }
}
export default connectLogout(Logout);

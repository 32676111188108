import React, { Component } from 'react';
import get from 'lodash/get';
import { connectStoreDetails } from 'utils/connectors/AppConnectors';
import { If } from 'utils';

class Title extends Component {
  render() {
    const storeDetails = this.props.getStoreDetails();
    const logo = storeDetails.logo;
    // Different store logos will need to have their own heights to be in sync with how their ECommerce
    // Store looks (Sarthak)
    let logoHeight = get(storeDetails, 'logoHeights.medium');
    if (logoHeight && !isNaN(logoHeight)) {
      logoHeight = Number(logoHeight);
    } else {
      logoHeight = '100%';
    }
    return (
      <div className="brand-id text-center">
        <If test={storeDetails.logo}>
          <div className="brand-logo">
            <img alt="" src={logo} style={{ height: logoHeight }} />
          </div>
        </If>
        <If test={!storeDetails.logo}>
          <div className="brand-logo-text">{storeDetails.name}</div>
        </If>
        <div className="brand-heading">Returns Center</div>
      </div>
    );
  }
}
export default connectStoreDetails(Title);

export default function loadDynamicScript({ id = '', url = '' }) {
  if (!id || !url) return;

  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }
}

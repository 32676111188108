import axios from 'axios';
import _get from 'lodash/get';
import _merge from 'lodash/merge';

import user from './../extra/user';

function post(apiUrl, payload, options) {
  options = options || {};
  const url = process.env.REACT_APP_BASE_URL + apiUrl;
  const token = user.getToken();

  const data = _get(payload, 'params', {});
  const config = _get(payload, 'config', {});

  const axiosConfig = _merge(
    { headers: { Authorization: `Token ${token}` } },
    config
  );

  if (options && options.noToken) {
    delete axiosConfig.headers.Authorization;
  }
  return axios.post(url, data, axiosConfig);
}

export default post;

import React, { useState, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { AuthContext } from 'context/AuthContext';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const AspenBenefitsSlider = () => {
  const [index, setIndex] = useState(0);
  const appContext = useContext(AuthContext);
  const aspenBenefits = _get(appContext, 'aspenBenefits');

  const { benefits, dots } = aspenBenefits.reduce(
    (acc, { img, label }, dotIndex) => {
      const isMarkerActive = index === dotIndex ? 'active' : '';
      return {
        ...acc,
        benefits: [
          ...acc.benefits,
          <Col key={label} md="12" sm="12" xs="12" className="benefit-col">
            <div className="benefit-fill">{img}</div>
            <div className="benefit-col-label">{label}</div>
          </Col>
        ],
        dots: [
          ...acc.dots,
          <div key={label} className={`dots ${isMarkerActive}`} />
        ]
      };
    },
    { benefits: [], dots: [] }
  );

  return (
    <>
      <Row className="login-aspenBenefits align-items-center justify-content-center no-gutters">
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={index => setIndex(index)}
          enableMouseEvents
        >
          {benefits}
        </AutoPlaySwipeableViews>
      </Row>
      <div className="login-swipe-dots">{dots}</div>
    </>
  );
};

export default AspenBenefitsSlider;

import { get } from 'utils';
import { logError } from 'utils/extra';
import { localStore } from 'utils';

function attachOtherReleventInfoTo(object) {
  return {
    ...object,
    paymentWaiveOff: {
      infoText: 'We have waived off all the charges for this exchange order'
    }
  };
}

function formatData(data) {
  var object = {};
  try {
    var keys = Object.keys(data);
    keys.forEach(key => {
      var itemObj = {};
      var itemArr = [];
      if (Array.isArray(data[key])) {
        data[key].forEach(item => {
          if (typeof item === 'string') {
            itemArr.push(item);
          } else {
            itemObj[item.value] = item.text;
          }
        });
      } else {
        itemObj = data[key];
      }

      if (itemArr.length) {
        object[key] = Object.assign([], itemArr);
      } else {
        object[key] = Object.assign({}, itemObj);
      }
    });
    object = attachOtherReleventInfoTo(object);
    return object;
  } catch (e) {
    logError(e);
    return null;
  }
}

export default function loadDictionaryPromise(callback = () => {}) {
  return get('/retailer/masterData/', {}, { noToken: true })
    .then(res => {
      const data = formatData(res.data);
      if (data) {
        localStore.put('dictionary', JSON.stringify(data));
      }
      callback();
    })
    .catch(e => {
      const error = e || 'Error in loading master data';
      logError(error);
      callback();
    });
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Footer } from 'components/Common';
import { logEvent } from 'utils/analytics';
import ReminderImage from 'images/other/reminder-feedback-image.svg';

function ReminderFeedback() {
  useEffect(() => {
    logEvent('Feedback Registered');
  }, []);
  return (
    <div className="ReminderFeedback">
      <h1 className="ReminderFeedback__h1">Feedback Registered</h1>
      <div className="ReminderFeedback__image">
        <img src={ReminderImage} alt="" />
      </div>
      <p className="ReminderFeedback__description">
        Thanks for letting us know. We've updated our records.
      </p>
      <Link to="/account/orders" className="ReminderFeedback__button">
        View Orders
      </Link>
      <Footer />
    </div>
  );
}

export default ReminderFeedback;

import classNames from 'classnames';
import React from 'react';
import { ReactComponent as AlertIcon } from 'images/icons/new/announcements.svg';
import { If } from 'utils';

const InfoBanner = ({
  children = null,
  lightBgColor = false,
  showIcon = false,
  isQrBanner = false,
  textDark = false,
  textLight = false,
  className = '',
  centerItems = false,
  Icon = AlertIcon,
  themeBg = false,
  iconLight = false,
  iconDark = false,
  iconLeft = false,
  iconTop = false
}) => {
  const bannerClassName = classNames(className, 'info-banner', {
    'light-bg-color': lightBgColor,
    'justify-content-between': isQrBanner,
    'justify-content-center': centerItems,
    'dark-text': textDark,
    'light-text': textLight,
    'dark-icon': iconDark,
    'light-theme-bg-color': themeBg,
    'light-icon': iconLight,
    'position-icon-left-border': iconLeft,
    'position-icon-top-border': iconTop
  });
  return (
    <div className={bannerClassName}>
      <If test={showIcon}>
        <Icon className="mr-3" />
      </If>

      {children}
    </div>
  );
};

export default InfoBanner;

import React from 'react';

export default function CovidDisclaimer() {
  return (
    <div className="covid-disclaimer">
      <p>
        AspenCX is a registered trademark of DataCulture Inc. AspenCX is a
        software technology that helps your store automate shipping, returns and
        exchanges. This information package has been created for the reference
        of customers who shop with our Merchant Partners. If you would like to
        learn more about our service or COVID19 precautions, please reach out to
        your store's customer support team. We strongly recommend that you
        practice safe interaction while receiving or handing over your package
        to a delivery person.
      </p>
    </div>
  );
}

import React, { useContext, useRef, useMemo } from 'react';
import moment from 'moment';
import cn from 'classnames';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import styles from './index.module.scss';

import Address from './Address';
import { AuthContext } from 'context/AuthContext';
import { ShipmentContext } from '../../context';
import { getTrackingUpdateStep, getTrackingUpdateCopy } from '../../methods';

export default function ShipmentUpdates() {
  const { config, updates } = useContext(ShipmentContext);
  const { getStoreDetails } = useContext(AuthContext);
  const { name } = useRef(getStoreDetails()).current;

  const storeName = _upperFirst(name);
  const shippedOn = _get(updates, 'shipped_on', '');
  const lastUpdatedAt = _get(updates, 'last_updated_at', '');
  const carrierTrackingPageUrl = _get(updates, 'carrier_tracking_page_url', '');
  const originAddress = _get(updates, 'origin_address', {});
  const currentAddress = _get(updates, 'current_address', {});
  const destinationAddress = _get(updates, 'destination_address', {});
  const progressBarColor = _get(config, 'tracking_info.progress_bar.color', '');

  const activeStep = useMemo(() => getTrackingUpdateStep(updates), [updates]);

  const formattedLastUpdatedAt = useMemo(() => {
    const date = new Date(lastUpdatedAt);
    return moment(date).format('MMM DD, YYYY [at] hh:mm a');
  }, [lastUpdatedAt]);

  const { title, deliveryEtaMsg, deliveryDate } = useMemo(() => {
    return getTrackingUpdateCopy(storeName, updates);
  }, [storeName, updates]);

  const shipmentProgress = useMemo(() => {
    const trackingUpdates = [
      `Shipped On: ${shippedOn}`,
      activeStep !== 1 ? (
        'In Transit'
      ) : (
        <Address
          compact
          data={currentAddress}
          className={styles.currentAddress}
        />
      ),
      'Out for Delivery',
      'Delivered'
    ];
    return trackingUpdates.map((update, index) => {
      const className = cn({
        [styles.done]: index <= activeStep,
        [styles.active]: index === activeStep
      });
      return (
        <li className={className} key={index}>
          <div>{update}</div>
        </li>
      );
    });
  }, [shippedOn, activeStep, currentAddress]);

  return (
    <main className={styles.container}>
      <h4 className={styles.brief}>{title}</h4>
      <div className={styles.card}>
        <div className={styles.eta}>
          <h5>{deliveryEtaMsg}</h5>
          <strong>{deliveryDate}</strong>
        </div>
        <div className={styles.summary}>
          <div className={styles.header}>
            <p>
              <span>Last Update:</span> {formattedLastUpdatedAt}
            </p>
            {carrierTrackingPageUrl && (
              <a
                href={carrierTrackingPageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Check on carrier website
                {/* <ExternalLinkIcon /> */}
              </a>
            )}
          </div>
          <ol
            className={styles.progress}
            style={{
              '--bar-color': progressBarColor,
              '--bar-size': `${(activeStep * 100) / 3}%`
            }}
          >
            {shipmentProgress}
          </ol>
          <div className={styles.address}>
            <Address
              data={originAddress}
              title="Shipped From"
              className={styles.from}
            />
            <Address
              data={destinationAddress}
              title="Arriving At"
              className={styles.to}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

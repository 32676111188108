import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Button, { Theme } from 'components/Button';
import { refreshSite } from './methods';
import errorImage from 'images/rr-illustractions/lost.png';

export default function NotFound() {
  const location = useLocation();

  const message =
    location.pathname.indexOf('/tracking/') > -1 ? (
      <span>
        We are syncing your tracking information. Please try again in few
        minutes. <br />
        If the problem persists, please contact customer support.
      </span>
    ) : (
      'Sorry. We couldn’t find what you were looking for. Please make sure the link in correct.'
    );
  return (
    <div className="ErrorPage">
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <div className="ErrorPage__image">
        <img src={errorImage} alt="not found" />
      </div>
      <div className="ErrorPage__text">{message}</div>
      <Button onClick={refreshSite} className="ErrorPage__button">
        Refresh this page
      </Button>
      <Button linkTo="/" theme={Theme.INLINE} className="ErrorPage__link">
        Back To Home
      </Button>
    </div>
  );
}

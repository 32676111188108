function getTrimedValue(inputVal) {
  if (typeof inputVal === 'string') return inputVal.trim();
  return inputVal;
}

function collectInputErrors({ inputs, checkList }) {
  /**
   * inputs : formik onsubmit dataset (input-value-object)
   * checkList: Array of Object with type, {
   *    'key'   : REQUIRED | ('name' attribute to input)
   *    'msg'   : REQUIRED | (message if error encountered)
   *    'check' : OPTIONAL | (explicit overriding check)
   * }
   */
  const DIFF_MSG = 'Atleast 1 character is required';
  return checkList.reduce((acc, { key, msg, isError }) => {
    const inputVal = getTrimedValue(inputs[key]);
    const diff = inputVal !== inputs[key];
    const condition = isError || !inputVal;

    if (condition) {
      if (isError === undefined && diff) acc[key] = DIFF_MSG;
      else acc[key] = msg;
    }
    return acc;
  }, {});
}

export { collectInputErrors, getTrimedValue };

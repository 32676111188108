import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { getTrackingInfoFromUrl } from '../methods';
import { getTrackingPageConfig, getTrackingData } from '../services';
import { DEVICE_TYPE, LAYOUT_BREAKPOINT, STATUS } from '../stubs';

export default function useTracking() {
  const { search } = useLocation();
  const [trackingInfo, setTrackingInfo] = useState(
    getTrackingInfoFromUrl(search)
  );
  const [config, setConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [isConfigError, setIsConfigError] = useState(false);
  const [updates, setUpdates] = useState(null);
  const [isUpdatesError, setIsUpdatesError] = useState(false);
  const [deviceType, setDeviceType] = useState(DEVICE_TYPE.MOBILE);
  const initialRef = useRef(true);

  const fetchConfig = (params = {}) => {
    setIsLoadingConfig(true);
    setIsConfigError(false);
    getTrackingPageConfig(
      params,
      setConfig,
      () => setIsConfigError(true),
      () => setIsLoadingConfig(false)
    );
  };

  useEffect(() => {
    window.addEventListener('resize', deviceSizeHandler);
    if (initialRef.current) {
      deviceSizeHandler();
      initialRef.current = false;
    }
    return () => {
      window.removeEventListener('resize', deviceSizeHandler);
    };
  }, []);

  useEffect(() => {
    if (_isEmpty(trackingInfo)) {
      fetchConfig();
    }
  }, []);

  useEffect(() => {
    if (!_isEmpty(trackingInfo)) {
      setIsLoading(true);
      setIsUpdatesError(false);
      getTrackingData(
        trackingInfo,
        ({ config, updates }) => {
          const status = _get(updates, 'status', null);
          const carrierUrl = _get(updates, 'carrier_tracking_page_url', '');
          if (!(status in STATUS) && carrierUrl) {
            return window.location.replace(carrierUrl);
          }
          setConfig(config);
          setUpdates(updates);
          setIsLoading(false);
          setIsLoadingConfig(false);
        },
        () => {
          setIsUpdatesError(true);
          setIsLoading(false);
          setIsLoadingConfig(false);
        }
      );
    }
  }, [trackingInfo]);

  const deviceSizeHandler = () => {
    setDeviceType(() => {
      const screenWidth = window.innerWidth;
      return screenWidth < LAYOUT_BREAKPOINT
        ? DEVICE_TYPE.MOBILE
        : DEVICE_TYPE.DESKTOP;
    });
  };

  return {
    config,
    isLoading,
    isLoadingConfig,
    isConfigError,
    updates,
    isUpdatesError,
    deviceType,
    setTrackingInfo
  };
}

import localStore from './extra/localStore.js';
import user from './extra/user.js';
import post from './request/post.js';
import put from './request/put';
import get from './request/get.js';
import deleteRequest from './request/delete';
import If from './If';
import logError from './extra/logError';
import isOnNewFlow from './isOnNewFlow';

export {
  get,
  localStore,
  user,
  post,
  put,
  deleteRequest,
  If,
  logError,
  isOnNewFlow
};

import { get, logError } from 'utils';
import { getUrlParameter } from 'utils/methods';

const LOG_ERROR_MSG = 'Unabled to load shipment configuration';

export default function getTrackingPageConfig(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const location = window.location;
  const domain =
    getUrlParameter(location.search, 'domain') || location.hostname;

  get(
    '/shipment-tracking/config',
    { params: { ...params, domain } },
    { noToken: true }
  )
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      logError(err, { customMessage: LOG_ERROR_MSG });
      onError(err);
    })
    .finally(onFinally);
}

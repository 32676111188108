import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';
import { LOGIN_TEMPLATE } from 'dictionary';

const AUTH_ROUTES = ['/login', '/gift-return'];

export default function useLoginLayout() {
  const { pathname } = useLocation();
  const { getStoreDetails } = useContext(AuthContext);

  const storeDetails = getStoreDetails();

  const isAuthPage = useMemo(() => {
    return AUTH_ROUTES.some(route => pathname.includes(route));
  }, [pathname]);

  const template = _get(storeDetails, 'loginPageTemplate', '');

  const isDefaultTemplate = template === LOGIN_TEMPLATE.ONE;
  const isTemplateTwo = template === LOGIN_TEMPLATE.TWO;

  return { isAuthPage, template, isDefaultTemplate, isTemplateTwo };
}

import { post } from 'utils';
function fetchOrder(params, success, error) {
  let retailer = window.retailer || '';
  if (retailer) {
    retailer = `?retailer=${retailer}`;
  } else {
    params.domain = (window.location || {}).hostname;
  }
  const request = post(
    `/auth/customer/lastOrderZipcode/${retailer}`,
    {
      params
    },
    {
      noToken: true
    }
  );
  request
    .then(res => {
      if (!res.data) {
        error('Something Went Wrong');
        return;
      }
      if (res.data && res.data.statusCode === 0) {
        success(res.data.data);
        return;
      }
      error('');
    })
    .catch(res => {
      error('401', 'Incorrect credentials.');
    });
}
export default fetchOrder;

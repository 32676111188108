import React, { useContext, useRef } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { ShipmentContext } from '../../context';
import { AuthContext } from 'context/AuthContext';

export default function Header() {
  const { config } = useContext(ShipmentContext);
  const { getStoreDetails } = useContext(AuthContext);
  const { name } = useRef(getStoreDetails()).current;

  const logoUrl = _get(config, 'header.logo_url', '');
  // const textAlign = _get(config, 'header.align', '');
  const backgroundColor = _get(config, 'header.background_color', '');

  return (
    <header className={styles.container} style={{ backgroundColor }}>
      <div className={styles.content}>
        {logoUrl ? (
          <img className={styles.logo} src={logoUrl} alt={name} />
        ) : (
          <h3 className={styles.storeTitle}>{name}</h3>
        )}
      </div>
    </header>
  );
}

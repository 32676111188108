import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import { isLogRocketDisabled } from 'config/setup/logRocketInit';

const logError = (error, extra) => {
  const disabledLogRocket = isLogRocketDisabled();

  if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    console.log('Log Error: ', error);
  }

  if (typeof extra !== 'object') {
    if (!disabledLogRocket) {
      LogRocket.captureException(error);
    }
    Sentry.captureException(error);
    return;
  }
  // NOTE: The values contained in the tags and extra objects must be scalar values (e.g., booleans, numbers, or strings).
  // Link: https://docs.logrocket.com/reference#captureexception
  // Right now we aren't checking for it here. (Sarthak)
  if (!disabledLogRocket) {
    LogRocket.captureException(error, { extra });
  }
  Sentry.withScope(scope => {
    Object.keys(extra).forEach(key => {
      scope.setExtra(key, extra[key]);
    });
    Sentry.captureException(error);
  });
};
export default logError;

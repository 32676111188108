import Color from 'color';
import cssVars from 'css-vars-ponyfill';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { logError } from 'utils/extra';

/*
 * The ponyfill is used for IE 9-11
 * By default it replaces css variable values only in legacy systems
  cssVars({
    onlyLegacy: true // default
  });
 */
const initialize = function(storeProperties) {
  // return;
  try {
    // If storeProperties is not passed, all existing root level css Variables are polyfilled
    // This happens when the polyfills are initially called in config/setup/index.js
    // Else when this function is called with the storeProperties,
    // only the overrides passed in storeProperties API are overridden. (Sarthak)
    if (!storeProperties) {
      return cssVars();
    }
    // Adding CSS variables overrides passed by Store Properties
    const storePropertiesJSON = JSON.parse(storeProperties);
    const themeingOverrides = _get(
      storePropertiesJSON,
      'store_color_theme.overrides',
      {}
    );
    const _themeingOverrides = addPrimaryColorVariants(themeingOverrides);
    cssVars({
      variables: _themeingOverrides
    });
  } catch (e) {
    logError(JSON.stringify(e));
  }
};

const addPrimaryColorVariants = themeColors => {
  const PRIMARY_COLOR_KEY = '--theme__primary--color';
  const _themeColors = { ...themeColors };
  const primaryColor = _get(_themeColors, PRIMARY_COLOR_KEY);
  _set(
    _themeColors,
    `${PRIMARY_COLOR_KEY}-variant1`,
    Color(primaryColor).alpha(0.7)
  );
  _set(
    _themeColors,
    `${PRIMARY_COLOR_KEY}-variant2`,
    Color(primaryColor).alpha(0.45)
  );
  _set(
    _themeColors,
    `${PRIMARY_COLOR_KEY}-variant3`,
    Color(primaryColor).alpha(0.25)
  );
  _set(
    _themeColors,
    `${PRIMARY_COLOR_KEY}-variant4`,
    Color(primaryColor).alpha(0.05)
  );

  return _themeColors;
};

export default initialize;

import React, { useMemo } from 'react';
import cn from 'classnames';

export default function OptionIcon({
  selected = false,
  disabled = false,
  inProgressId = false
}) {
  const icon = useMemo(() => {
    return cn({
      'fas fa-check-circle': selected,
      'far fa-circle': !selected && !disabled,
      'fas fa-circle': !selected && disabled && !inProgressId,
      'fa fa-spinner fa-spin': !selected && disabled && inProgressId
    });
  }, [selected, disabled, inProgressId]);

  return (
    <span className={cn('option-icon', { selected, disabled })}>
      <i className={icon} />
    </span>
  );
}

import React, { useState, useContext, useRef } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _toLower from 'lodash/toLower';
import styles from './index.module.scss';

import { Radio } from 'components/UiKit';
import { AuthContext } from 'context/AuthContext';
import { ShipmentContext } from 'views/ShipmentTrackingApp/context';
import { DEVICE_TYPE, RADIO } from 'views/ShipmentTrackingApp/stubs';

export default function DataForm() {
  const [text, setText] = useState('');
  const [isError, setIsError] = useState('');
  const [mode, setMode] = useState(RADIO.VALUES.ORDER_NAME);
  const {
    config,
    isLoading,
    deviceType,
    isUpdatesError,
    setTrackingInfo
  } = useContext(ShipmentContext);
  const { getStoreDetails } = useContext(AuthContext);
  const { name } = useRef(getStoreDetails()).current;
  const inputRef = useRef();
  const label = _get(RADIO.LABELS, mode, '');
  const queryType = _toLower(_get(RADIO.LABELS, mode, ''));
  const primaryColor = _get(config, 'tracking_info.progress_bar.color', '#000');

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onModeChange = mode => {
    setMode(mode);
    setIsError(false);
    focusInput();
  };

  const onTextChange = e => {
    setText(e.target.value);
    setIsError(false);
  };

  const onTrackShipment = e => {
    e.preventDefault();

    if (!text) {
      setIsError(true);
      return focusInput();
    }
    setTrackingInfo({ [mode]: text });
  };

  const formClassName = cn(styles.form, {
    [styles.desktop]: deviceType === DEVICE_TYPE.DESKTOP,
    [styles.mobile]: deviceType === DEVICE_TYPE.MOBILE
  });

  const inputContainerClassName = cn(styles.inputContainer, {
    [styles.error]: isError || isUpdatesError
  });

  return (
    <div className={styles.container} style={{ '--bg-color': primaryColor }}>
      <h1 className={styles.title}>Track your {name} order</h1>
      <form className={formClassName} onSubmit={onTrackShipment}>
        <Radio
          inline
          selectedOption={mode}
          options={RADIO.OPTIONS}
          onChange={onModeChange}
          wrapperClassName={styles.radioList}
          className={styles.radioOption}
          labelClassName={styles.radioLabel}
          checkedIconClassName={styles.radioIconChecked}
        />
        <div className={inputContainerClassName}>
          <label htmlFor="tracking-input">{label}</label>
          <input
            type="text"
            value={text}
            id="tracking-input"
            onChange={onTextChange}
            disabled={isLoading}
            placeholder={`Please enter the ${queryType}`}
            ref={inputRef}
          />
          {isUpdatesError && <span>No tracking information found!</span>}
        </div>
        <button disabled={isError || isLoading} className={styles.confirmBtn}>
          {isLoading ? 'Fetching Updates...' : 'Track my order'}
        </button>
      </form>
    </div>
  );
}

import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { ShipmentContext } from 'views/ShipmentTrackingApp/context';
import { DEVICE_TYPE } from 'views/ShipmentTrackingApp/stubs';

export default function Footer() {
  const { config, deviceType } = useContext(ShipmentContext);

  const socials = _get(config, 'socials', {});
  const show = _get(socials, 'show', false);
  const title = _get(socials, 'title.text', false);
  const titleColor = _get(socials, 'title.color', false);
  const titleAlign = _get(socials, 'title.align', false);
  const iconColor = _get(socials, 'icon_color', false);
  const platforms = _get(socials, 'platforms', false);

  if (!show) return null;

  const wrapperClassName = useMemo(() => {
    return cn(styles.container, {
      [styles.desktop]: deviceType === DEVICE_TYPE.DESKTOP,
      [styles.mobile]: deviceType === DEVICE_TYPE.MOBILE
    });
  }, [deviceType]);

  const socialsRender = platforms.reduce((acc, { id, link }) => {
    const iconLink = `https://mc.sendgrid.com/assets/social/white/${id}.png`;
    const customStyle = { backgroundColor: iconColor };
    if (link) {
      acc.push(
        <li key={id}>
          <a
            href={link}
            target="_blank"
            style={customStyle}
            rel="noopener noreferrer"
          >
            <img src={iconLink} alt={id} />
          </a>
        </li>
      );
    }
    return acc;
  }, []);

  return (
    <footer className={wrapperClassName}>
      {title && (
        <h5
          className={styles.title}
          style={{ color: titleColor, textAlign: titleAlign }}
        >
          {title}
        </h5>
      )}
      <ol className={styles.socials}>{socialsRender}</ol>
    </footer>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import './scss/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import setup from './config/setup';
// import { GoogleAnalyticsProvider } from './utils/googleAnalytics';
import { AppProvider } from 'context/AppContext';
import { AuthProvider } from 'context/AuthContext';
import App from './App';
// import { unregister } from './registerServiceWorker';
//import registerServiceWorker from './registerServiceWorker';

setup();

//Provide context for connected route.
ReactDOM.render(
  <Router>
    {/* <GoogleAnalyticsProvider> */}
    <AppProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </AppProvider>
    {/* </GoogleAnalyticsProvider> */}
  </Router>,
  document.getElementById('root')
);

// unregister();
//registerServiceWorker();

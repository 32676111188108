import {
  isAlvies,
  isGeologie,
  isJemma,
  isHoneylove,
  isFuseLenses,
  isHellolesley,
  isForeverYoung,
  isTheLittleBraCompany
} from 'utils/retailer';

export default function isDefaultLoginZipCode() {
  return (
    isAlvies() ||
    isGeologie() ||
    isJemma() ||
    isHoneylove() ||
    isFuseLenses() ||
    isHellolesley() ||
    isForeverYoung() ||
    isTheLittleBraCompany()
  );
}

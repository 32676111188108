import React, { useContext, useRef, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import _get from 'lodash/get';

import Layout from './Layout';
import Logout from './views/Logout';
import { ErrorPage } from 'views/Errors';
import QrShippingLabel from './views/QrShippingLabel';
import ShipmentTrackingApp from 'views/ShipmentTrackingApp';
import rrFaviconFallback from 'images/rr-favicon.svg';
import trFaviconFallback from 'images/tr-favicon.svg';
import { AuthContext } from 'context/AuthContext';
import Retailer404 from 'Layout/Retailer404';

export default function App() {
  const { appType, getStoreDetails } = useContext(AuthContext);

  const fallbackFavicon = useRef(
    appType.shipmentTracking ? trFaviconFallback : rrFaviconFallback
  ).current;

  const { storeName, faviconLink } = useMemo(() => {
    const storeInfo = getStoreDetails();
    const storeName = _get(storeInfo, 'name', '');
    const faviconLink = storeInfo.favicon || fallbackFavicon;
    return { storeName, faviconLink };
  }, [getStoreDetails]);

  return (
    <>
      <Helmet>
        <title>{storeName ? `${storeName} Support` : 'Returns Portal'}</title>
        <link rel="shortcut icon" href={faviconLink} />
      </Helmet>
      <Retailer404>
        {appType.retex && (
          <Switch>
            <Route path="/logout" component={Logout} />
            <Route path="/error" component={ErrorPage} />
            <Route exact path="/qr-shipping-label">
              <QrShippingLabel storeName={storeName} />
            </Route>
            <Route path="/" component={Layout} />
          </Switch>
        )}
        {appType.shipmentTracking && (
          <Switch>
            <Route path="/" component={ShipmentTrackingApp} />
          </Switch>
        )}
      </Retailer404>
    </>
  );
}

import React from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';

import { If } from 'utils';
import Button, { Theme } from 'components/Button';
import { refreshSite } from './methods';
import errorImage from 'images/rr-illustractions/lost.png';

export default function ErrorPage({
  hideHomeButton = false,
  tabTitle = 'Error | Something went wrong',
  message = 'Something went wrong. Please retry or check your connection.\n If the error persists, please contact support',
  imageContainerClassName = '',
  imageClassName = '',
  testClassName = '',
  buttonClassName = '',
  linkClassName = ''
}) {
  return (
    <div className="ErrorPage">
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      <div className={cn('ErrorPage__image', imageContainerClassName)}>
        <img src={errorImage} alt="not found" className={imageClassName} />
      </div>
      <div className={cn('ErrorPage__text', testClassName)}>{message}</div>
      <Button
        onClick={refreshSite}
        className={cn('ErrorPage__button', buttonClassName)}
      >
        Refresh this page
      </Button>
      <If test={!hideHomeButton}>
        <Button
          linkTo="/"
          theme={Theme.INLINE}
          className={cn('ErrorPage__link', linkClassName)}
        >
          Back To Home
        </Button>
      </If>
    </div>
  );
}

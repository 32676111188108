// From: https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes
// Based of: https://en.wikipedia.org/wiki/ISO_3166-1#Officially_assigned_code_elements

const countries = [
  {
    name: 'Afghanistan',
    value: 'AF',
    'country-code': '004'
  },
  {
    name: 'Åland Islands',
    value: 'AX',
    'country-code': '248'
  },
  {
    name: 'Albania',
    value: 'AL',
    'country-code': '008'
  },
  {
    name: 'Algeria',
    value: 'DZ',
    'country-code': '012'
  },
  {
    name: 'American Samoa',
    value: 'AS',
    'country-code': '016'
  },
  {
    name: 'Andorra',
    value: 'AD',
    'country-code': '020'
  },
  {
    name: 'Angola',
    value: 'AO',
    'country-code': '024'
  },
  {
    name: 'Anguilla',
    value: 'AI',
    'country-code': '660'
  },
  {
    name: 'Antarctica',
    value: 'AQ',
    'country-code': '010'
  },
  {
    name: 'Antigua and Barbuda',
    value: 'AG',
    'country-code': '028'
  },
  {
    name: 'Argentina',
    value: 'AR',
    'country-code': '032'
  },
  {
    name: 'Armenia',
    value: 'AM',
    'country-code': '051'
  },
  {
    name: 'Aruba',
    value: 'AW',
    'country-code': '533'
  },
  {
    name: 'Australia',
    value: 'AU',
    'country-code': '036'
  },
  {
    name: 'Austria',
    value: 'AT',
    'country-code': '040'
  },
  {
    name: 'Azerbaijan',
    value: 'AZ',
    'country-code': '031'
  },
  {
    name: 'Bahamas',
    value: 'BS',
    'country-code': '044'
  },
  {
    name: 'Bahrain',
    value: 'BH',
    'country-code': '048'
  },
  {
    name: 'Bangladesh',
    value: 'BD',
    'country-code': '050'
  },
  {
    name: 'Barbados',
    value: 'BB',
    'country-code': '052'
  },
  {
    name: 'Belarus',
    value: 'BY',
    'country-code': '112'
  },
  {
    name: 'Belgium',
    value: 'BE',
    'country-code': '056'
  },
  {
    name: 'Belize',
    value: 'BZ',
    'country-code': '084'
  },
  {
    name: 'Benin',
    value: 'BJ',
    'country-code': '204'
  },
  {
    name: 'Bermuda',
    value: 'BM',
    'country-code': '060'
  },
  {
    name: 'Bhutan',
    value: 'BT',
    'country-code': '064'
  },
  {
    name: 'Bolivia (Plurinational State of)',
    value: 'BO',
    'country-code': '068'
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    'country-code': '535'
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
    'country-code': '070'
  },
  {
    name: 'Botswana',
    value: 'BW',
    'country-code': '072'
  },
  {
    name: 'Bouvet Island',
    value: 'BV',
    'country-code': '074'
  },
  {
    name: 'Brazil',
    value: 'BR',
    'country-code': '076'
  },
  {
    name: 'British Indian Ocean Territory',
    value: 'IO',
    'country-code': '086'
  },
  {
    name: 'Brunei Darussalam',
    value: 'BN',
    'country-code': '096'
  },
  {
    name: 'Bulgaria',
    value: 'BG',
    'country-code': '100'
  },
  {
    name: 'Burkina Faso',
    value: 'BF',
    'country-code': '854'
  },
  {
    name: 'Burundi',
    value: 'BI',
    'country-code': '108'
  },
  {
    name: 'Cabo Verde',
    value: 'CV',
    'country-code': '132'
  },
  {
    name: 'Cambodia',
    value: 'KH',
    'country-code': '116'
  },
  {
    name: 'Cameroon',
    value: 'CM',
    'country-code': '120'
  },
  {
    name: 'Canada',
    value: 'CA',
    'country-code': '124'
  },
  {
    name: 'Cayman Islands',
    value: 'KY',
    'country-code': '136'
  },
  {
    name: 'Central African Republic',
    value: 'CF',
    'country-code': '140'
  },
  {
    name: 'Chad',
    value: 'TD',
    'country-code': '148'
  },
  {
    name: 'Chile',
    value: 'CL',
    'country-code': '152'
  },
  {
    name: 'China',
    value: 'CN',
    'country-code': '156'
  },
  {
    name: 'Christmas Island',
    value: 'CX',
    'country-code': '162'
  },
  {
    name: 'Cocos (Keeling) Islands',
    value: 'CC',
    'country-code': '166'
  },
  {
    name: 'Colombia',
    value: 'CO',
    'country-code': '170'
  },
  {
    name: 'Comoros',
    value: 'KM',
    'country-code': '174'
  },
  {
    name: 'Congo',
    value: 'CG',
    'country-code': '178'
  },
  {
    name: 'Congo, Democratic Republic of the',
    value: 'CD',
    'country-code': '180'
  },
  {
    name: 'Cook Islands',
    value: 'CK',
    'country-code': '184'
  },
  {
    name: 'Costa Rica',
    value: 'CR',
    'country-code': '188'
  },
  {
    name: "Côte d'Ivoire",
    value: 'CI',
    'country-code': '384'
  },
  {
    name: 'Croatia',
    value: 'HR',
    'country-code': '191'
  },
  {
    name: 'Cuba',
    value: 'CU',
    'country-code': '192'
  },
  {
    name: 'Curaçao',
    value: 'CW',
    'country-code': '531'
  },
  {
    name: 'Cyprus',
    value: 'CY',
    'country-code': '196'
  },
  {
    name: 'Czechia',
    value: 'CZ',
    'country-code': '203'
  },
  {
    name: 'Denmark',
    value: 'DK',
    'country-code': '208'
  },
  {
    name: 'Djibouti',
    value: 'DJ',
    'country-code': '262'
  },
  {
    name: 'Dominica',
    value: 'DM',
    'country-code': '212'
  },
  {
    name: 'Dominican Republic',
    value: 'DO',
    'country-code': '214'
  },
  {
    name: 'Ecuador',
    value: 'EC',
    'country-code': '218'
  },
  {
    name: 'Egypt',
    value: 'EG',
    'country-code': '818'
  },
  {
    name: 'El Salvador',
    value: 'SV',
    'country-code': '222'
  },
  {
    name: 'Equatorial Guinea',
    value: 'GQ',
    'country-code': '226'
  },
  {
    name: 'Eritrea',
    value: 'ER',
    'country-code': '232'
  },
  {
    name: 'Estonia',
    value: 'EE',
    'country-code': '233'
  },
  {
    name: 'Eswatini',
    value: 'SZ',
    'country-code': '748'
  },
  {
    name: 'Ethiopia',
    value: 'ET',
    'country-code': '231'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    value: 'FK',
    'country-code': '238'
  },
  {
    name: 'Faroe Islands',
    value: 'FO',
    'country-code': '234'
  },
  {
    name: 'Fiji',
    value: 'FJ',
    'country-code': '242'
  },
  {
    name: 'Finland',
    value: 'FI',
    'country-code': '246'
  },
  {
    name: 'France',
    value: 'FR',
    'country-code': '250'
  },
  {
    name: 'French Guiana',
    value: 'GF',
    'country-code': '254'
  },
  {
    name: 'French Polynesia',
    value: 'PF',
    'country-code': '258'
  },
  {
    name: 'French Southern Territories',
    value: 'TF',
    'country-code': '260'
  },
  {
    name: 'Gabon',
    value: 'GA',
    'country-code': '266'
  },
  {
    name: 'Gambia',
    value: 'GM',
    'country-code': '270'
  },
  {
    name: 'Georgia',
    value: 'GE',
    'country-code': '268'
  },
  {
    name: 'Germany',
    value: 'DE',
    'country-code': '276'
  },
  {
    name: 'Ghana',
    value: 'GH',
    'country-code': '288'
  },
  {
    name: 'Gibraltar',
    value: 'GI',
    'country-code': '292'
  },
  {
    name: 'Greece',
    value: 'GR',
    'country-code': '300'
  },
  {
    name: 'Greenland',
    value: 'GL',
    'country-code': '304'
  },
  {
    name: 'Grenada',
    value: 'GD',
    'country-code': '308'
  },
  {
    name: 'Guadeloupe',
    value: 'GP',
    'country-code': '312'
  },
  {
    name: 'Guam',
    value: 'GU',
    'country-code': '316'
  },
  {
    name: 'Guatemala',
    value: 'GT',
    'country-code': '320'
  },
  {
    name: 'Guernsey',
    value: 'GG',
    'country-code': '831'
  },
  {
    name: 'Guinea',
    value: 'GN',
    'country-code': '324'
  },
  {
    name: 'Guinea-Bissau',
    value: 'GW',
    'country-code': '624'
  },
  {
    name: 'Guyana',
    value: 'GY',
    'country-code': '328'
  },
  {
    name: 'Haiti',
    value: 'HT',
    'country-code': '332'
  },
  {
    name: 'Heard Island and McDonald Islands',
    value: 'HM',
    'country-code': '334'
  },
  {
    name: 'Holy See',
    value: 'VA',
    'country-code': '336'
  },
  {
    name: 'Honduras',
    value: 'HN',
    'country-code': '340'
  },
  {
    name: 'Hong Kong',
    value: 'HK',
    'country-code': '344'
  },
  {
    name: 'Hungary',
    value: 'HU',
    'country-code': '348'
  },
  {
    name: 'Iceland',
    value: 'IS',
    'country-code': '352'
  },
  {
    name: 'India',
    value: 'IN',
    'country-code': '356'
  },
  {
    name: 'Indonesia',
    value: 'ID',
    'country-code': '360'
  },
  {
    name: 'Iran (Islamic Republic of)',
    value: 'IR',
    'country-code': '364'
  },
  {
    name: 'Iraq',
    value: 'IQ',
    'country-code': '368'
  },
  {
    name: 'Ireland',
    value: 'IE',
    'country-code': '372'
  },
  {
    name: 'Isle of Man',
    value: 'IM',
    'country-code': '833'
  },
  {
    name: 'Israel',
    value: 'IL',
    'country-code': '376'
  },
  {
    name: 'Italy',
    value: 'IT',
    'country-code': '380'
  },
  {
    name: 'Jamaica',
    value: 'JM',
    'country-code': '388'
  },
  {
    name: 'Japan',
    value: 'JP',
    'country-code': '392'
  },
  {
    name: 'Jersey',
    value: 'JE',
    'country-code': '832'
  },
  {
    name: 'Jordan',
    value: 'JO',
    'country-code': '400'
  },
  {
    name: 'Kazakhstan',
    value: 'KZ',
    'country-code': '398'
  },
  {
    name: 'Kenya',
    value: 'KE',
    'country-code': '404'
  },
  {
    name: 'Kiribati',
    value: 'KI',
    'country-code': '296'
  },
  {
    name: "Korea (Democratic People's Republic of)",
    value: 'KP',
    'country-code': '408'
  },
  {
    name: 'Korea, Republic of',
    value: 'KR',
    'country-code': '410'
  },
  {
    name: 'Kuwait',
    value: 'KW',
    'country-code': '414'
  },
  {
    name: 'Kyrgyzstan',
    value: 'KG',
    'country-code': '417'
  },
  {
    name: "Lao People's Democratic Republic",
    value: 'LA',
    'country-code': '418'
  },
  {
    name: 'Latvia',
    value: 'LV',
    'country-code': '428'
  },
  {
    name: 'Lebanon',
    value: 'LB',
    'country-code': '422'
  },
  {
    name: 'Lesotho',
    value: 'LS',
    'country-code': '426'
  },
  {
    name: 'Liberia',
    value: 'LR',
    'country-code': '430'
  },
  {
    name: 'Libya',
    value: 'LY',
    'country-code': '434'
  },
  {
    name: 'Liechtenstein',
    value: 'LI',
    'country-code': '438'
  },
  {
    name: 'Lithuania',
    value: 'LT',
    'country-code': '440'
  },
  {
    name: 'Luxembourg',
    value: 'LU',
    'country-code': '442'
  },
  {
    name: 'Macao',
    value: 'MO',
    'country-code': '446'
  },
  {
    name: 'Madagascar',
    value: 'MG',
    'country-code': '450'
  },
  {
    name: 'Malawi',
    value: 'MW',
    'country-code': '454'
  },
  {
    name: 'Malaysia',
    value: 'MY',
    'country-code': '458'
  },
  {
    name: 'Maldives',
    value: 'MV',
    'country-code': '462'
  },
  {
    name: 'Mali',
    value: 'ML',
    'country-code': '466'
  },
  {
    name: 'Malta',
    value: 'MT',
    'country-code': '470'
  },
  {
    name: 'Marshall Islands',
    value: 'MH',
    'country-code': '584'
  },
  {
    name: 'Martinique',
    value: 'MQ',
    'country-code': '474'
  },
  {
    name: 'Mauritania',
    value: 'MR',
    'country-code': '478'
  },
  {
    name: 'Mauritius',
    value: 'MU',
    'country-code': '480'
  },
  {
    name: 'Mayotte',
    value: 'YT',
    'country-code': '175'
  },
  {
    name: 'Mexico',
    value: 'MX',
    'country-code': '484'
  },
  {
    name: 'Micronesia (Federated States of)',
    value: 'FM',
    'country-code': '583'
  },
  {
    name: 'Moldova, Republic of',
    value: 'MD',
    'country-code': '498'
  },
  {
    name: 'Monaco',
    value: 'MC',
    'country-code': '492'
  },
  {
    name: 'Mongolia',
    value: 'MN',
    'country-code': '496'
  },
  {
    name: 'Montenegro',
    value: 'ME',
    'country-code': '499'
  },
  {
    name: 'Montserrat',
    value: 'MS',
    'country-code': '500'
  },
  {
    name: 'Morocco',
    value: 'MA',
    'country-code': '504'
  },
  {
    name: 'Mozambique',
    value: 'MZ',
    'country-code': '508'
  },
  {
    name: 'Myanmar',
    value: 'MM',
    'country-code': '104'
  },
  {
    name: 'Namibia',
    value: 'NA',
    'country-code': '516'
  },
  {
    name: 'Nauru',
    value: 'NR',
    'country-code': '520'
  },
  {
    name: 'Nepal',
    value: 'NP',
    'country-code': '524'
  },
  {
    name: 'Netherlands',
    value: 'NL',
    'country-code': '528'
  },
  {
    name: 'New Caledonia',
    value: 'NC',
    'country-code': '540'
  },
  {
    name: 'New Zealand',
    value: 'NZ',
    'country-code': '554'
  },
  {
    name: 'Nicaragua',
    value: 'NI',
    'country-code': '558'
  },
  {
    name: 'Niger',
    value: 'NE',
    'country-code': '562'
  },
  {
    name: 'Nigeria',
    value: 'NG',
    'country-code': '566'
  },
  {
    name: 'Niue',
    value: 'NU',
    'country-code': '570'
  },
  {
    name: 'Norfolk Island',
    value: 'NF',
    'country-code': '574'
  },
  {
    name: 'North Macedonia',
    value: 'MK',
    'country-code': '807'
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP',
    'country-code': '580'
  },
  {
    name: 'Norway',
    value: 'NO',
    'country-code': '578'
  },
  {
    name: 'Oman',
    value: 'OM',
    'country-code': '512'
  },
  {
    name: 'Pakistan',
    value: 'PK',
    'country-code': '586'
  },
  {
    name: 'Palau',
    value: 'PW',
    'country-code': '585'
  },
  {
    name: 'Palestine, State of',
    value: 'PS',
    'country-code': '275'
  },
  {
    name: 'Panama',
    value: 'PA',
    'country-code': '591'
  },
  {
    name: 'Papua New Guinea',
    value: 'PG',
    'country-code': '598'
  },
  {
    name: 'Paraguay',
    value: 'PY',
    'country-code': '600'
  },
  {
    name: 'Peru',
    value: 'PE',
    'country-code': '604'
  },
  {
    name: 'Philippines',
    value: 'PH',
    'country-code': '608'
  },
  {
    name: 'Pitcairn',
    value: 'PN',
    'country-code': '612'
  },
  {
    name: 'Poland',
    value: 'PL',
    'country-code': '616'
  },
  {
    name: 'Portugal',
    value: 'PT',
    'country-code': '620'
  },
  {
    name: 'Puerto Rico',
    value: 'PR',
    'country-code': '630'
  },
  {
    name: 'Qatar',
    value: 'QA',
    'country-code': '634'
  },
  {
    name: 'Réunion',
    value: 'RE',
    'country-code': '638'
  },
  {
    name: 'Romania',
    value: 'RO',
    'country-code': '642'
  },
  {
    name: 'Russian Federation',
    value: 'RU',
    'country-code': '643'
  },
  {
    name: 'Rwanda',
    value: 'RW',
    'country-code': '646'
  },
  {
    name: 'Saint Barthélemy',
    value: 'BL',
    'country-code': '652'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
    'country-code': '654'
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'KN',
    'country-code': '659'
  },
  {
    name: 'Saint Lucia',
    value: 'LC',
    'country-code': '662'
  },
  {
    name: 'Saint Martin (French part)',
    value: 'MF',
    'country-code': '663'
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'PM',
    'country-code': '666'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VC',
    'country-code': '670'
  },
  {
    name: 'Samoa',
    value: 'WS',
    'country-code': '882'
  },
  {
    name: 'San Marino',
    value: 'SM',
    'country-code': '674'
  },
  {
    name: 'Sao Tome and Principe',
    value: 'ST',
    'country-code': '678'
  },
  {
    name: 'Saudi Arabia',
    value: 'SA',
    'country-code': '682'
  },
  {
    name: 'Senegal',
    value: 'SN',
    'country-code': '686'
  },
  {
    name: 'Serbia',
    value: 'RS',
    'country-code': '688'
  },
  {
    name: 'Seychelles',
    value: 'SC',
    'country-code': '690'
  },
  {
    name: 'Sierra Leone',
    value: 'SL',
    'country-code': '694'
  },
  {
    name: 'Singapore',
    value: 'SG',
    'country-code': '702'
  },
  {
    name: 'Sint Maarten (Dutch part)',
    value: 'SX',
    'country-code': '534'
  },
  {
    name: 'Slovakia',
    value: 'SK',
    'country-code': '703'
  },
  {
    name: 'Slovenia',
    value: 'SI',
    'country-code': '705'
  },
  {
    name: 'Solomon Islands',
    value: 'SB',
    'country-code': '090'
  },
  {
    name: 'Somalia',
    value: 'SO',
    'country-code': '706'
  },
  {
    name: 'South Africa',
    value: 'ZA',
    'country-code': '710'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    'country-code': '239'
  },
  {
    name: 'South Sudan',
    value: 'SS',
    'country-code': '728'
  },
  {
    name: 'Spain',
    value: 'ES',
    'country-code': '724'
  },
  {
    name: 'Sri Lanka',
    value: 'LK',
    'country-code': '144'
  },
  {
    name: 'Sudan',
    value: 'SD',
    'country-code': '729'
  },
  {
    name: 'Suriname',
    value: 'SR',
    'country-code': '740'
  },
  {
    name: 'Svalbard and Jan Mayen',
    value: 'SJ',
    'country-code': '744'
  },
  {
    name: 'Sweden',
    value: 'SE',
    'country-code': '752'
  },
  {
    name: 'Switzerland',
    value: 'CH',
    'country-code': '756'
  },
  {
    name: 'Syrian Arab Republic',
    value: 'SY',
    'country-code': '760'
  },
  {
    name: 'Taiwan, Province of China',
    value: 'TW',
    'country-code': '158'
  },
  {
    name: 'Tajikistan',
    value: 'TJ',
    'country-code': '762'
  },
  {
    name: 'Tanzania, United Republic of',
    value: 'TZ',
    'country-code': '834'
  },
  {
    name: 'Thailand',
    value: 'TH',
    'country-code': '764'
  },
  {
    name: 'Timor-Leste',
    value: 'TL',
    'country-code': '626'
  },
  {
    name: 'Togo',
    value: 'TG',
    'country-code': '768'
  },
  {
    name: 'Tokelau',
    value: 'TK',
    'country-code': '772'
  },
  {
    name: 'Tonga',
    value: 'TO',
    'country-code': '776'
  },
  {
    name: 'Trinidad and Tobago',
    value: 'TT',
    'country-code': '780'
  },
  {
    name: 'Tunisia',
    value: 'TN',
    'country-code': '788'
  },
  {
    name: 'Turkey',
    value: 'TR',
    'country-code': '792'
  },
  {
    name: 'Turkmenistan',
    value: 'TM',
    'country-code': '795'
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'TC',
    'country-code': '796'
  },
  {
    name: 'Tuvalu',
    value: 'TV',
    'country-code': '798'
  },
  {
    name: 'Uganda',
    value: 'UG',
    'country-code': '800'
  },
  {
    name: 'Ukraine',
    value: 'UA',
    'country-code': '804'
  },
  {
    name: 'United Arab Emirates',
    value: 'AE',
    'country-code': '784'
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GB',
    'country-code': '826'
  },
  {
    name: 'United States of America',
    value: 'US',
    'country-code': '840'
  },
  {
    name: 'United States Minor Outlying Islands',
    value: 'UM',
    'country-code': '581'
  },
  {
    name: 'Uruguay',
    value: 'UY',
    'country-code': '858'
  },
  {
    name: 'Uzbekistan',
    value: 'UZ',
    'country-code': '860'
  },
  {
    name: 'Vanuatu',
    value: 'VU',
    'country-code': '548'
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    value: 'VE',
    'country-code': '862'
  },
  {
    name: 'Viet Nam',
    value: 'VN',
    'country-code': '704'
  },
  {
    name: 'Virgin Islands (British)',
    value: 'VG',
    'country-code': '092'
  },
  {
    name: 'Virgin Islands (U.S.)',
    value: 'VI',
    'country-code': '850'
  },
  {
    name: 'Wallis and Futuna',
    value: 'WF',
    'country-code': '876'
  },
  {
    name: 'Western Sahara',
    value: 'EH',
    'country-code': '732'
  },
  {
    name: 'Yemen',
    value: 'YE',
    'country-code': '887'
  },
  {
    name: 'Zambia',
    value: 'ZM',
    'country-code': '894'
  },
  {
    name: 'Zimbabwe',
    value: 'ZW',
    'country-code': '716'
  }
];

export default countries;

import qs from 'qs';
import _get from 'lodash/get';

import { INPUT_ID } from '../stubs';

export default function getDefaultInputValue(
  type = '',
  querySearchString = ''
) {
  const queryParams = qs.parse(querySearchString, { ignoreQueryPrefix: true });

  switch (type) {
    case INPUT_ID.EMAIL:
    case INPUT_ID.ORDER:
      return _get(queryParams, type, '');
    case INPUT_ID.PHONE:
    case INPUT_ID.ZIP:
    case INPUT_ID.GIFT_ZIP:
    case INPUT_ID.FIRST_NAME:
    case INPUT_ID.LAST_NAME:
    default:
      return '';
  }
}

import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function PhoneNumber({
  value = '',
  className = '',
  disabled = false,
  onChange = () => {},
  onBlur = () => {}
}) {
  return (
    <PhoneInput
      international
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      defaultCountry="US"
      disabled={disabled}
      className={className}
      countryCallingCodeEditable={false}
      placeholder="Please enter your phone number"
    />
  );
}

import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

export default function ExternalLink({
  href = '',
  className = '',
  onClick = () => {},
  children = null
}) {
  if (!href) return null;

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cn('nav-link', styles.container, className)}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

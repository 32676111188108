import React from 'react';
import cn from 'classnames';

import FacilitiesInfo from './FacilitiesInfo';
import FacilitiesLoader from './FacilitiesLoader';

export default function QrLabelLocations({
  compact = false,
  zipCode,
  locations,
  isLoading,
  isError
}) {
  return (
    <div className="qrLabelLocations__table">
      <div className="qrLabelLocations__tableHeader">
        <div className="qrLabelLocations__headerCol address">ADDRESS</div>
        <div className="qrLabelLocations__headerCol slots">WORKING HOURS</div>
      </div>
      <ul className={cn('qrLabelLocations__list', { compact: !!compact })}>
        <FacilitiesLoader
          loading={isLoading}
          isError={isError}
          htmlElement="li"
        >
          <FacilitiesInfo data={locations} zipCode={zipCode} />
        </FacilitiesLoader>
      </ul>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _get from 'lodash/get';

function formatDateRange(start, end) {
  const starts = moment(start, 'dddd').format('ddd');
  const ends = moment(end, 'dddd').format('ddd');
  return `${starts} - ${ends}`;
}

function formatServiceTime(start, end) {
  const opensAt = moment(start, 'HH:mm:ss').format('h:mm A');
  const closesAt = moment(end, 'HH:mm:ss').format('h:mm A');
  return `${opensAt} to ${closesAt}`;
}

export default function FacilitiesInfo({ data, zipCode }) {
  const [searchedZip, setSearchedZip] = useState(zipCode);
  const isError = Array.isArray(data);
  const locations = _get(data, 'carrierFacilitySuggestions', null);

  useEffect(() => {
    setSearchedZip(zipCode);
  }, [data]);

  if (isError) {
    return (
      <li key="-1" className="qrLabelLocations__notSearched">
        No facilities found for the zip code - {searchedZip}. Optionally, you
        can opt for the regular label instead of a QR code.
      </li>
    );
  } else if (Array.isArray(locations) && locations.length > 0) {
    return locations.map(({ address = {}, facilityHours = [] }, index) => {
      const { addressLines, cityTown, stateProvince } = address;
      let days = '';
      let facilityTiming = '';

      if (facilityHours && facilityHours.length > 0) {
        const { day: startDay, facilityTimings } = facilityHours[0];
        const { day: endDay } = facilityHours[facilityHours.length - 1];
        days = formatDateRange(startDay, endDay);
        const [{ opensAt, closesAt }] = facilityTimings;
        facilityTiming = formatServiceTime(opensAt, closesAt);
      }

      return (
        <li key={index} className="qrLabelLocations__listItem">
          <div className="qrLabelLocations__listItemCol address">
            <span>{addressLines.join(', ')}</span>
            <br />
            <span>
              {cityTown}, {stateProvince}
            </span>
          </div>
          <div className="qrLabelLocations__listItemCol slots">
            {days ? (
              <>
                <span>{days}:</span>
                <br />
                <span>{facilityTiming}</span>
              </>
            ) : (
              '-'
            )}
          </div>
        </li>
      );
    });
  } else {
    return (
      <li key="-1" className="qrLabelLocations__notSearched">
        Enter your zipcode and search to get a list of facilities supporting the
        QR Code label
      </li>
    );
  }
}

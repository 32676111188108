import { getUrlParameter } from 'utils/methods';

export default function getTrackingInfoFromUrl(queryParams) {
  const trackingNumber = getUrlParameter(queryParams, 'tracking_number');
  const orderName = getUrlParameter(queryParams, 'order_name');

  const trackingInfo = {};
  if (trackingNumber) trackingInfo.tracking_number = trackingNumber;
  else if (orderName) trackingInfo.order_name = orderName;

  return trackingInfo;
}

import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';

import { If } from 'utils';
import { AuthContext } from 'context/AuthContext';

export default function Logo() {
  const { getStoreDetails } = useContext(AuthContext);
  const storeDetails = useRef(getStoreDetails()).current;

  const logoSrc = _get(storeDetails, 'logo', '');
  const storeName = _get(storeDetails, 'name', '');
  let logoHeight = _get(storeDetails, 'logoHeights.small');
  logoHeight = !isNaN(logoHeight) ? +logoHeight : '100%';

  return (
    <Link to="/" className="navbar-brand">
      <If test={logoSrc}>
        <img
          src={logoSrc}
          alt={storeName}
          style={{ height: logoHeight }}
          className="d-inline-block align-top logo-img"
        />
      </If>
      <If test={!logoSrc}>
        <div className="d-inline-block align-top logo-img-text">
          {storeName}
        </div>
      </If>
    </Link>
  );
}

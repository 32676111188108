import React from 'react';

import Button from 'components/Button';
import COPIES from '../stubs/copies';
import { ReactComponent as QrCodeIcon } from 'images/icons/qr-code.svg';
import { ReactComponent as SearchIcon } from 'images/icons/search-small.svg';

export default function Header({
  zipCode = '',
  isLoading = false,
  onChangeZipCode = () => {},
  onSearchLocations = () => {}
}) {
  const submitHandler = e => {
    e.preventDefault();
    onSearchLocations();
  };
  return (
    <div className="qrShippingLabel__header">
      <div className="qrShippingLabel__qrIcon">
        <QrCodeIcon />
      </div>
      <div className="qrShippingLabel__headerContent">
        <h1 className="qrShippingLabel__headerTitle">{COPIES.TITLE}</h1>
        <p className="qrShippingLabel__headerDescription">
          {COPIES.DESCRIPTION}
        </p>
        <div className="qrShippingLabel__inputWrapper">
          <label>{COPIES.INPUT_LABEL}</label>
          <form className="qrShippingLabel__inputRow" onSubmit={submitHandler}>
            <input
              type="number"
              value={zipCode}
              disabled={isLoading}
              onChange={e => onChangeZipCode(e.target.value)}
              placeholder="Enter Zipcode"
            />
            <Button disabled={!zipCode || isLoading} isLoading={isLoading}>
              <SearchIcon />
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

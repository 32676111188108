import React, { useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';
import { logEvent } from 'utils/analytics';
import { useLoginLayout } from 'utils/hooks';
import { AuthContext } from 'context/AuthContext';
import Logo from '../Logo';
import Guest from '../Guest';
import LoggedIn from '../LoggedIn';
import ExternalLink from '../ExternalLink';
import TrackingHeader from '../TrackingHeader';
import { ReactComponent as LeftArrow } from 'images/icons/chevron-left.svg';

export default function Header() {
  const { pathname } = useLocation();
  const { isLoggedIn, userData, getStoreDetails } = useContext(AuthContext);
  const storeDetails = useRef(getStoreDetails()).current;
  const { isDefaultTemplate } = useLoginLayout();

  const logo = _get(storeDetails, 'logo', '');
  const storeName = _get(storeDetails, 'name', '');
  const logoHeight = _get(storeDetails, 'logoHeights.small');
  const storeWesiteUrl = _get(storeDetails, 'website', '');

  const isLoginPage = pathname.includes('/login');
  const isGiftLoginPage = pathname.includes('/gift-login');
  const isCovidInfoPage = pathname.includes('/COVID');
  const isTrackingPage = pathname.includes('/tracking');

  const isAuthPage = isLoginPage || isGiftLoginPage;
  const containerClass = cn('container', { loggedOut: !isLoggedIn });

  const onStoreLinkClick = () => {
    logEvent('Click: Return to Store in Header');
  };

  const onPolicyLinkClick = () => {
    logEvent('Click: FAQs in Header');
  };

  if (isTrackingPage || isCovidInfoPage) {
    return <TrackingHeader logo={logo} height={logoHeight} name={storeName} />;
  } else if (isAuthPage && !isDefaultTemplate) {
    return (
      <ExternalLink
        href={storeWesiteUrl}
        className={styles.link}
        onClick={onStoreLinkClick}
      >
        <LeftArrow />
        <span>Back to Store</span>
      </ExternalLink>
    );
  }

  return (
    <div id="header">
      <header className="app-header  headerDefault">
        <div className={containerClass}>
          <nav className="navbar sticky-top pl-0 pr-0">
            <Logo />
            <If test={userData}>
              <LoggedIn data={userData} />
            </If>
            <If test={!userData}>
              <Guest
                onStoreLinkClick={onStoreLinkClick}
                onPolicyLinkClick={onPolicyLinkClick}
              />
            </If>
          </nav>
        </div>
      </header>
    </div>
  );
}

import React from 'react';
import { connectStoreDetails } from 'utils/connectors/AppConnectors';

const StaticCol = props => {
  const storeDetails = props.getStoreDetails();
  const styles = { width: '100%', height: '100%' };
  const loginBackground =
    storeDetails.loginBackground ||
    'https://res.cloudinary.com/supply-ai/image/upload/v1559136809/retailers/default/login-background-fallback.jpg';
  styles.backgroundImage = `url(${loginBackground})`;
  return <div id="login-col-offset" style={styles} />;
  // <div id="login-col-offset" className="col-md-6" style={styles} />
};

export default connectStoreDetails(StaticCol);

import axios from 'axios';

import user from './../extra/user';
function put(apiUrl, payload, options) {
  const url = process.env.REACT_APP_BASE_URL + apiUrl;
  const token = user.getToken();
  let headers = {
    headers: {
      Authorization: 'Token ' + token
    }
  };
  if (options && options.noToken) {
    delete headers.headers;
  }
  return axios.put(url, payload.params, {
    ...headers,
    params: payload.queryParams || {}
  });
}

export default put;

import Address from './Address';
import TitleMetaTag from './TitleMetaTag';
import StrikedText from './StrikedText';
import OrderPrice from './OrderPrice';
import Loading from './Loading';
import Footer from './Footer';
import ExchangeDifference from './ExchangeDifference';
import PoweredByBar from './PoweredByBar';
import QrLabelLinks from './QrLabelLinks';
import LogoStamp from './LogoStamp';
import DynamicTextColor from './DynamicTextColor';

export {
  Address,
  TitleMetaTag,
  StrikedText,
  OrderPrice,
  Loading,
  Footer,
  ExchangeDifference,
  PoweredByBar,
  QrLabelLinks,
  LogoStamp,
  DynamicTextColor
};

import React from 'react';

export default function FacilitiesLoader({
  loading = false,
  isError = false,
  children = null,
  htmlElement = 'div'
}) {
  if (!loading && !isError) {
    return children;
  } else if (loading) {
    return React.createElement(
      htmlElement,
      { className: 'qrLabelLocations__loadingLocations' },
      <LoaderSkeleton />
    );
  } else if (isError) {
    return React.createElement(
      htmlElement,
      { className: 'qrLabelLocations__errorLocations' },
      <ErrorAlert />
    );
  } else {
    return null;
  }
}

function ErrorAlert() {
  return (
    <>We are not able to fetch locations at the moment. Please try later.</>
  );
}

function LoaderSkeleton() {
  return (
    <>
      <div></div>
      <div></div>
      <div></div>
    </>
  );
}

import React, { useContext } from 'react';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import styles from './index.module.scss';

import { Footer } from 'components/Common';
import { AuthContext } from 'context/AuthContext';

export default function StoreUnserviceableAlert() {
  const { getStoreDetails, canCreateRequest } = useContext(AuthContext);

  const storeDetails = getStoreDetails();
  const storeName = _upperFirst(_get(storeDetails, 'name', ''));

  if (canCreateRequest) return null;

  return (
    <div className={styles.container}>
      <h4>
        {storeName} is not accepting any more online requests at this time.
      </h4>
      <p>
        We’re sorry for the inconvenience. Please contact support to get further
        assistance.
      </p>
      <Footer className={styles.contact} isMerchantSpecific={false} />
    </div>
  );
}

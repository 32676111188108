import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { ShipmentContext } from '../../context';
import { DEVICE_TYPE } from 'views/ShipmentTrackingApp/stubs';

export default function Recommendations({ className = '' }) {
  const { config, deviceType } = useContext(ShipmentContext);
  const isBannerOneEnabled = _get(config, `banner1.${deviceType}.show`, false);
  const recommendations = _get(config, 'recommendation', []);
  const isMobile = deviceType === DEVICE_TYPE.MOBILE;
  const spread = !isBannerOneEnabled || isMobile;

  const wrapperClassName = useMemo(() => {
    return cn(styles.container, {
      [className]: !!className,
      [styles.fullWide]: spread
    });
  }, [className, spread]);

  const listClassName = useMemo(() => {
    return cn(styles.products, {
      [styles.grid]: !spread,
      [styles.spread]: spread,
      [styles.mobile]: isMobile
    });
  }, [spread, isMobile, deviceType]);

  const recommendedProducts = useMemo(() => {
    return recommendations.map((product, index) => {
      return (
        <li key={index} className={styles.product}>
          <a
            href={product.shopifyLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.imageContainer}>
              {product.image_url && (
                <img src={product.image_url} alt={product.title} />
              )}
            </div>
            <span className={styles.productName}>{product.title}</span>
          </a>
        </li>
      );
    });
  }, [recommendations]);

  if (_isEmpty(recommendations)) return null;

  return (
    <div className={wrapperClassName}>
      <h5 className={styles.title}>You May Also Like</h5>
      <ul className={listClassName}>{recommendedProducts}</ul>
    </div>
  );
}

import qs from 'qs';

import { INPUT_ID } from '../stubs';
import isDefaultLoginPhone from './isDefaultLoginPhone';
import isDefaultLoginZipCode from './isDefaultLoginZipCode';

export default function getDefaultInputIds(
  querySearchString = '',
  allowPhoneLogin = false
) {
  const queryParams = qs.parse(querySearchString, { ignoreQueryPrefix: true });
  const hasEmailInQueryParams = INPUT_ID.EMAIL in queryParams;
  const hasOrderNumberInQueryParams = INPUT_ID.ORDER in queryParams;

  const inputOneType = hasEmailInQueryParams
    ? INPUT_ID.EMAIL
    : isDefaultLoginPhone(allowPhoneLogin)
    ? INPUT_ID.PHONE
    : INPUT_ID.EMAIL;

  const inputTwoType = hasOrderNumberInQueryParams
    ? INPUT_ID.ORDER
    : isDefaultLoginZipCode()
    ? INPUT_ID.ZIP
    : INPUT_ID.ORDER;

  return [inputOneType, inputTwoType];
}

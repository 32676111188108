import _get from 'lodash/get';

import { LOGIN_TEMPLATE } from 'dictionary';
import { logError } from 'utils/extra';
import { isHoneylove } from 'utils/retailer';

function formatStoreProperties(storeProperties) {
  // Whenever one of the critical keys isn't present, the UI doesn't load
  //TODO: Can move this function (to find and match keys) outside as a generic one in utils and
  //keep the logic in this function short and specific
  const criticalKeys = ['store_name', 'store_website'];
  const areCriticalKeysPresent = criticalKeys.reduce((bool, key) => {
    const value = _get(storeProperties, key);
    if (value) {
      return bool && true;
      // If the loop is encountering the first property that is missing, send an error to Sentry (Sarthak)
    } else if (bool) {
      logError(`Store Property missing - ${key}`);
    }
    return false;
  }, true);
  if (!areCriticalKeysPresent) {
    return {};
  }
  let websiteURL = '';
  let isWebsiteValid = true;
  try {
    websiteURL = new URL(storeProperties.store_website);
  } catch (e) {
    // Handling invalid website URL format ( Sarthak )
    logError(`Website URL is invalid - ${storeProperties.store_website}`);
    isWebsiteValid = false;
  }
  const storeDetailsObject = {
    name: storeProperties.store_name,
    email: isHoneylove() ? '' : storeProperties.store_support_email,
    website: storeProperties.store_website,
    websiteName: isWebsiteValid
      ? `${websiteURL.host}${websiteURL.pathname}`.replace(/\/$/, '')
      : storeProperties.store_website, // Removing protocol
    returnPolicyPage: storeProperties.store_return_policy_link,
    logo: _get(storeProperties, 'store_image_links.logo'),
    favicon: _get(storeProperties, 'store_image_links.favicon'),
    isGiftReturnEnabled: storeProperties.is_gift_return_enabled,
    sizeChartLink: storeProperties.store_sizing_guide_link,
    loginBackground: _get(
      storeProperties,
      'store_image_links.login_background_image.order_return'
    ),
    giftReturnBackground: _get(
      storeProperties,
      'store_image_links.login_background_image.gift_return'
    ),
    logoHeights: storeProperties.logo_height,
    retailerPhone: storeProperties.retailer_phone,
    allowOutOfStockExchange: storeProperties.honour_inventory_policy,
    storeOrderText: {
      example: _get(storeProperties, 'order.name_example'),
      helpText: _get(storeProperties, 'order.name_help_text'),
      nameRegEx: _get(storeProperties, 'order.name_regex'),
      nameImage: _get(storeProperties, 'order.name_image')
    },
    serviceLabel: {
      name: _get(storeProperties, 'service_label.name')
    },
    retailer_id: storeProperties.retailer_id,
    aspencxBranding: storeProperties.aspencx_branding,
    storeTheme: _get(storeProperties, 'store_color_theme.overrides', {}),
    allowGiftReturns: _get(storeProperties, 'is_gift_return_order_enabled'),
    loginPageTemplate: _get(
      storeProperties,
      'store_color_theme.login_page_template',
      LOGIN_TEMPLATE.ONE
    )
  };

  return storeDetailsObject;
}

export default formatStoreProperties;

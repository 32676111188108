import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { isValidEmail } from 'utils/methods';
import { INPUT_ID } from '../stubs';

function isInputValid(value = '', id = '') {
  switch (id) {
    case INPUT_ID.EMAIL: {
      if (!value) return 'Required';
      const isValid = isValidEmail(value);
      return isValid ? '' : 'Invalid email address';
    }
    case INPUT_ID.PHONE:
      if (!value) return 'Required';
      const isValid = isPossiblePhoneNumber(value);
      return isValid ? '' : 'Invalid phone number';
    case INPUT_ID.ORDER:
    case INPUT_ID.FIRST_NAME:
    case INPUT_ID.LAST_NAME:
    case INPUT_ID.GIFT_ZIP:
      return !value ? 'Required' : '';
    default:
      return '';
  }
}

function validateForm(data = []) {
  if (!_isArray(data)) {
    throw new Error('Login Form data for validation was not correct');
  }
  return data.map(inputData => {
    const value = _get(inputData, 'value', '');
    const id = _get(inputData, 'htmlInfo.id', '');
    return isInputValid(value, id);
  });
}

export { isInputValid, validateForm };

import React, { useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { Loading } from 'components';
import {
  Header,
  ShipmentUpdates,
  Recommendations,
  Banner,
  Footer,
  DataForm
} from './components';
import { useTracking } from './hooks';
import { ShipmentContextProvider } from './context';

import { DEVICE_TYPE } from './stubs';

export default function ShipmentTrackingApp() {
  const {
    config,
    // isConfigError,
    updates,
    isLoading,
    isLoadingConfig,
    isUpdatesError,
    deviceType,
    setTrackingInfo
  } = useTracking();

  const getDeviceClassNames = () => {
    return cn({
      [styles.desktop]: deviceType === DEVICE_TYPE.DESKTOP,
      [styles.mobile]: deviceType === DEVICE_TYPE.MOBILE
    });
  };

  const showGridSection = useMemo(() => {
    const isRecommendationsVisible = !_isEmpty(
      _get(config, 'recommendation', [])
    );
    const isBannerOneVisible = _get(
      config,
      `banner1.${deviceType}.show`,
      false
    );
    return isRecommendationsVisible || isBannerOneVisible;
  }, [config, deviceType]);

  const gridSectionClassName = useMemo(() => {
    return cn(styles.gridRow, getDeviceClassNames());
  }, [deviceType]);

  const bannerOneClassName = useMemo(() => {
    const recommendations = _get(config, 'recommendation', []);
    return cn(styles.banner1, { [styles.fullWide]: _isEmpty(recommendations) });
  }, [config]);

  const bannerTwoClassName = useMemo(() => {
    return cn(styles.banner2, getDeviceClassNames());
  }, [deviceType]);

  const haveTrackingUpdates = !isLoading && !_isEmpty(updates);

  return (
    <div className={styles.container}>
      <Loading loading={isLoadingConfig}>
        <ShipmentContextProvider
          value={{
            config,
            updates,
            isLoading,
            deviceType,
            isUpdatesError,
            setTrackingInfo
          }}
        >
          <Header />
          <div className={styles.content}>
            {!haveTrackingUpdates ? (
              <DataForm />
            ) : (
              <div className={styles.trackingUpdates}>
                <ShipmentUpdates />
                {showGridSection && (
                  <section className={gridSectionClassName}>
                    <Recommendations className={styles.recommendation} />
                    <Banner id="banner1" className={bannerOneClassName} />
                  </section>
                )}
                <Banner
                  id="banner2"
                  className={styles.banner2Container}
                  bannerClassName={bannerTwoClassName}
                />
                <Footer />
              </div>
            )}
          </div>
        </ShipmentContextProvider>
      </Loading>
    </div>
  );
}

import { logError } from 'utils';

const validateCoordinates = (address = {}) => {
  const { latitude, longitude } = address;
  try {
    if (!latitude || !longitude) {
      throw new Error('Invalid coordinates');
    }
    if (latitude > 90 || latitude < -90) {
      throw new Error('Invalid latitude');
    }
    if (longitude > 180 || longitude < -180) {
      logError('Invalid longitude but ignored', address);
    }
    return true;
  } catch (e) {
    logError(e, address);
    return false;
  }
};

export default validateCoordinates;

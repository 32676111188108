export function buildOrderDetailsRoute(orderID) {
  return routes.orderDetails.replace(':id', encodeURIComponent(orderID));
}

const routes = {
  account: '/account',
  orders: '/orders',
  orderDetails: '/orders/:id',
  orderTrack: '/orders/:orderID/track/:fulfillmentID',
  orderServiceTrack: '/orders/:orderID/request/:serviceID',
  retexTrack: '/orders/:orderID/retex/:serviceID', // TODO SARTHAK: Change to retexID
  orderService: '/orderService'
};

export default routes;

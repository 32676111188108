import React from 'react';

export default function FormSuggestionText({
  value = '',
  regex = '',
  helpText = ''
}) {
  if (!value || !helpText) {
    return null;
  }

  // Our Exchange order
  if (
    value.toUpperCase().startsWith('EXC') ||
    value.toUpperCase().startsWith('EEXC')
  ) {
    return null;
  }

  const regexp = new RegExp(regex, 'i');

  return value.match(regexp) ? null : (
    <div className="FormSuggestionText">{helpText}</div>
  );
}

import axios from 'axios';
import { CustomToast } from 'components';
import { logoutUser } from 'utils/extra';
import get from 'lodash/get';

function setup() {
  axios.defaults.timeout = 30000; // 30 seconds
  axios.interceptors.response.use(
    function(response) {
      const res = response.data;
      res.statusCode = response.status;
      res.notified = false;
      notify(res);
      if (!res.data || res.data === null) {
        const error = new Error('Api Error: - url'); //TODO : Add url of page
        error.displayMessage = 'Error fetching data. Please try again later';
        error.responseReceived = res;
        return Promise.reject(error);
      }
      return res;
    },
    function(error) {
      // error is an instance of Error
      // Default error.message example: Request failed with status 404
      // We change it too GET 500 <URL_NAME>
      // Modifying error.message
      const url = get(error, 'config.url');
      const method = (get(error, 'config.method') || '').toUpperCase();
      const status =
        get(error, 'response.status') !== null
          ? get(error, 'response.status')
          : -1;
      error.message = `${method} ${status} ${url}`;
      // Checking 401 and 403 Unauthorized case
      const headers = get(error, 'config.headers') || {};
      if (
        (status === 401 || status === 403) &&
        headers['Authorization'] &&
        headers['Authorization'].length > 0
      ) {
        logoutUser(); // force user logout if 401 received for that token
        return error;
      }
      const responseData = get(error, 'response.data');
      error.method = get(error, 'config.method');
      error.statusCode = status;
      error.url = url;
      error.notified = notify(responseData);
      if (!error.notified) {
        error.displayMessage = get(responseData, 'displayMessage');
      }
      return Promise.reject(error);
    }
  );
}

function notify(res) {
  if (res && res.displayMessage && res.displayMessage.showToUser === true) {
    const msgObject = res.displayMessage;
    CustomToast({ msg: msgObject.message, type: msgObject.level });
    delete res.displayMessage;
    res.notified = true;
    return true;
  }
  return false;
}

export default setup;

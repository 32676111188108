import React from 'react';

class StrikedText extends React.Component {
  render() {
    const { children } = this.props;
    return <span style={{ textDecoration: 'line-through' }}>{children}</span>;
  }
}

export default StrikedText;

export default function(options) {
  if (!options) {
    return null;
  }
  return Object.keys(options)
    .map(key => {
      return `${key}: ${options[key]}`;
    })
    .join(' | ');
}

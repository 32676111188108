import React, { useContext, useRef } from 'react';
import _get from 'lodash/get';

import { If } from 'utils';
import { AuthContext } from 'context/AuthContext';
import ExternalLink from './ExternalLink';

export default function Guest({
  onStoreLinkClick = () => {},
  onPolicyLinkClick = () => {}
}) {
  const { getStoreDetails } = useContext(AuthContext);
  const storeDetails = useRef(getStoreDetails()).current;

  const storeWesiteUrl = _get(storeDetails, 'website', '');
  const returnPolicyPageUrl = _get(storeDetails, 'returnPolicyPage', '');

  return (
    <ul className="nav nav-pills nav-fill">
      <li className="nav-item">
        <ExternalLink href={storeWesiteUrl} onClick={onStoreLinkClick}>
          Back to Store
        </ExternalLink>
      </li>
      <If test={returnPolicyPageUrl}>
        <li className="nav-item">
          <ExternalLink href={returnPolicyPageUrl} onClick={onPolicyLinkClick}>
            Return Policy
          </ExternalLink>
        </li>
      </If>
    </ul>
  );
}

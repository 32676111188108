import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

import {
  getDefaultInputValue,
  getFormInputHtmlData,
  isInputValid
} from '../../../methods';

export default function useInput(type = '', storeInfo = {}) {
  const { search } = useLocation();
  const [value, setValue] = useState(getDefaultInputValue(type, search));
  const [error, setError] = useState('');
  const [htmlInfo, setHtmlInfo] = useState();
  const checkErrorRef = useRef(false);
  const initialRef = useRef(true);

  useEffect(() => {
    const storeOrderText = _get(storeInfo, 'storeOrderText', {});
    const htmlInfo = getFormInputHtmlData(type, storeOrderText);
    setError('');
    setHtmlInfo(htmlInfo);
    if (!initialRef.current) {
      const value = getDefaultInputValue(type, search);
      setValue(value);
      initialRef.current = false;
    } else {
      // Reset value on input type change was causing issue when switching between email and phone
      setValue('');
    }
  }, [type, search]);

  useEffect(() => {
    if (error && !checkErrorRef.current) {
      checkErrorRef.current = true;
    }
  }, [error]);

  const validate = value => {
    const error = isInputValid(value, type);
    setError(error);
  };

  const onBlur = () => validate(value);

  const onChange = value => {
    setValue(value);
    if (checkErrorRef.current) {
      validate(value);
    }
  };

  const onUpdateError = error => {
    if (!_isString(error)) return;
    setError(error);
  };

  return {
    value,
    error,
    htmlInfo,
    onBlur,
    onChange,
    onUpdateError
  };
}

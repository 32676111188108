import React from 'react';

import { If } from 'utils/';

const TrackingHeader = ({ logo, height, name }) => {
  let logoHeight = height + 'px';
  return (
    <header className="trackingHeader align-center">
      <If test={logo}>
        <img
          src={logo}
          alt=""
          style={{ height: `${logoHeight}`, maxHeight: '100%' }}
        />
      </If>
      <If test={!logo}>
        <span className="trackingHeader__name">{name}</span>
      </If>
    </header>
  );
};
export default TrackingHeader;

import _get from 'lodash/get';
import moment from 'moment';

import { STATUS } from '../stubs';

export default function getTrackingUpdateCopy(storeName, updates) {
  const status = _get(updates, 'status', '');
  const orderName = _get(updates, 'order_name', '');
  const deliveryEta = _get(updates, 'eta', '');
  const lastUpdatedAt = _get(updates, 'last_updated_at', '');

  const copy = {
    title: `Your ${storeName} order ${orderName}`,
    deliveryEtaMsg: '',
    deliveryDate: ''
  };

  switch (status) {
    case STATUS.SHIPPED:
      copy.title = `${copy.title} has been shipped.`;
      copy.deliveryEtaMsg = 'Expected Delivery by';
      copy.deliveryDate = moment(new Date(deliveryEta)).format('MMM DD');
      break;
    case STATUS.IN_TRANSIT:
      copy.title = `${copy.title} is on it’s way.`;
      copy.deliveryEtaMsg = 'Expected Delivery by';
      copy.deliveryDate = moment(new Date(deliveryEta)).format('MMM DD');
      break;
    case STATUS.OUT_FOR_DELIVERY:
      copy.title = `${copy.title} is out for delivery.`;
      copy.deliveryEtaMsg = 'Expected Delivery by';
      copy.deliveryDate = moment(new Date(deliveryEta)).format('MMM DD');
      break;
    case STATUS.DELIVERED:
      copy.title = `${copy.title} has been delivered.`;
      copy.deliveryEtaMsg = 'Delivered on';
      copy.deliveryDate = moment(new Date(lastUpdatedAt)).format('MMM DD');
      break;
    default:
      break;
  }
  return copy;
}

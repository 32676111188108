import React, { useRef, useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';
import cn from 'classnames';
import styles from './index.module.scss';

import { If } from 'utils';
import {
  getUrlParameter,
  showAspenBenefits,
  showPoweredByAspen
} from 'utils/methods';
import { useLoginLayout } from 'utils/hooks';
import { logEvent } from 'utils/analytics';
import AspenBenefits from 'components/AspenBenefits';
import { TitleMetaTag, LogoStamp } from 'components/Common';
import { AuthContext } from 'context/AuthContext';
import { StaticCol } from 'views/Login/components';
import { ReactComponent as GiftIcon } from 'images/icons/new/gift-icon.svg';
import { Link, useLocation } from 'react-router-dom';
import ExternalLink from 'views/Templates/Store/ExternalLink';

const withLoginLayout = (Component = () => null) => {
  return props => {
    const { pathname = '' } = useLocation();
    const { getStoreDetails } = useContext(AuthContext);
    const showAspenBranding = useRef(showPoweredByAspen()).current;
    const isAspenBenefitsVisible = useRef(showAspenBenefits()).current;
    const storeDetails = useRef(getStoreDetails()).current;
    const { isDefaultTemplate, isTemplateTwo } = useLoginLayout();
    const isLoginScreen = pathname === '/login';

    const storeEmail = _get(storeDetails, 'email', '');
    const returnPolicyPageUrl = _get(storeDetails, 'returnPolicyPage', '');
    const ifGiftReturnsEnabled = _get(storeDetails, 'allowGiftReturns', false);
    const brandImageColSize = isTemplateTwo ? 12 : 7;
    const showBenefits =
      isDefaultTemplate && isAspenBenefitsVisible && isLoginScreen;
    const containerClassName = cn('login-form-col', {
      floating: isTemplateTwo
    });
    const brandImageClassName = cn('login-image-col', {
      fixed: isTemplateTwo
    });
    const formWrapperClassName = cn('FormWrapper', { 'mx-0': isTemplateTwo });
    const giftReturnLinkClassName = cn('FormWrapper gift_return_link py-3', {
      'mx-0': isTemplateTwo
    });
    const supportLinkClassName = cn('FormWrapper py-3 support_link_wrapper', {
      'mx-0': isTemplateTwo
    });

    useEffect(() => {
      const location = window.location.search;
      logEvent('Login: Start', { from: getUrlParameter(location, 'from') });
    }, []);

    return (
      <div
        className="container-fluid login-wrapper"
        style={{
          background: 'white',
          '--offset': isDefaultTemplate ? '71px' : '0px'
        }}
      >
        <TitleMetaTag name="Login" />
        <Row className="login-row">
          <Col md="5" sm="12" className={containerClassName}>
            <div id="login-col" className="login-col-wrapper">
              <div className="LoginForm__wrapper">
                <div className={formWrapperClassName}>
                  <Component {...props} />
                </div>
                <If test={ifGiftReturnsEnabled}>
                  <div className={giftReturnLinkClassName}>
                    <GiftIcon />
                    <span>
                      {isLoginScreen
                        ? 'Received a gift?'
                        : 'Not returning a gift?'}
                    </span>
                    <Link
                      to={isLoginScreen ? '/gift-login' : '/login'}
                      className="FormWrapper__relatedLinks"
                    >
                      <div className="text-underline">
                        <strong>Click here</strong>
                      </div>
                    </Link>
                  </div>
                </If>
                <If test={storeEmail}>
                  <div className={supportLinkClassName}>
                    Contact Support:{' '}
                    <a
                      href={`mailto:${storeEmail}`}
                      className="FormWrapper__relatedLinks login-help-link"
                    >
                      <div className="text-underline">
                        <strong>{storeEmail}</strong>
                      </div>
                    </a>
                  </div>
                </If>
              </div>
            </div>
            <If test={showBenefits}>
              <AspenBenefits />
            </If>
            <If test={isTemplateTwo}>
              <ExternalLink href={returnPolicyPageUrl} className={styles.link}>
                Return Policy
              </ExternalLink>
            </If>
          </Col>
          <Col md={brandImageColSize} sm="12" className={brandImageClassName}>
            <StaticCol />
          </Col>
        </Row>
        <If test={showAspenBranding}>
          {/* <PoweredByBar /> */}
          <LogoStamp />
        </If>
      </div>
    );
  };
};

export default withLoginLayout;

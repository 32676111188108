import React from 'react';
import { Button } from 'reactstrap';
var classNames = require('classnames');
/**
 * Custom Button made for portal
 *
 * @visibleName The Best Button Ever 🐙
 * @prop  {String} size - Props for button size. Default is : default | Options : large
 * @prop {String} disabled - Props for disable button. Default is : false | Options : true
 * @prop {String} className - Props for button class. Default is : button_ui_landing | Options : button_ui_landing_disabled
 */
function ButtonUI(props) {
  const customClass =
    classNames({
      button_ui_landing: true,
      button_ui_landing_disabled: props.disabled
    }) +
    ' ' +
    props.className;
  let left = props.icon === 'fa-arrow-left' ? ' left_order' : '';
  let icon = null;
  if (!props.noIcon) {
    icon = props.icon || 'fa-arrow-right';
  }
  return (
    <Button
      className={customClass}
      disabled={props.disabled ? true : false}
      onClick={props.onClick}
      data-cy-button={props.dataAttr || 'action-button'}
    >
      {props.children ? (
        <span className="button_children">
          <span className={'button_children-text ' + left}>
            {props.children}
          </span>

          {icon ? <i className={`fas ${icon}`} /> : null}
        </span>
      ) : (
        // <div className="button_children">{props.children}</div>
        'CONTINUE'
      )}
    </Button>
  );
}

export default ButtonUI;

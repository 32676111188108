import React from 'react';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { If } from 'utils';
import { ReactComponent as CheckIcon } from 'images/icons/select-check.svg';

export default function Radio({
  name = '',
  label = '',
  inline = false,
  options = [],
  disable = false,
  selectedOption = '',
  onChange = () => {},
  className = '',
  wrapperClassName = '',
  labelClassName = '',
  checkedIconClassName = ''
}) {
  const radioOptions = options.map(({ label, value, disabled }) => {
    const uniqueId = uuidv4();
    return (
      <RadioOption
        key={uniqueId}
        name={name}
        checked={value === selectedOption}
        onChange={() => onChange(value)}
        disabled={disable || disabled}
        className={className}
        label={label}
        labelClassName={labelClassName}
        checkedIconClassName={checkedIconClassName}
      />
    );
  });
  const listClassName = cn('uiKit__radioList', {
    spread: inline,
    stack: !inline,
    [wrapperClassName]: !!wrapperClassName
  });

  return (
    <div className="uiKit__element">
      <If test={label}>
        <div className="uiKit__element--labelWrapper">
          <label>{label}</label>
        </div>
      </If>
      <ul className={listClassName}>{radioOptions}</ul>
    </div>
  );
}

function RadioOption({
  name = '',
  label = '',
  checked = false,
  disabled = false,
  onChange = () => {},
  className = '',
  labelClassName = '',
  checkedIconClassName = ''
}) {
  const uniqueId = uuidv4();
  const classname = cn('uiKit__radio', {
    [className]: !!className
  });
  const labelClass = cn('selection-title', {
    checked: !!checked,
    [labelClassName]: !!labelClassName
  });
  const iconClassName = cn({
    [checkedIconClassName]: checked && !!checkedIconClassName
  });
  return (
    <li className={classname}>
      <input
        id={uniqueId}
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={uniqueId} className={iconClassName}>
        {checked && <CheckIcon />}
      </label>
      <label htmlFor={uniqueId} className={labelClass}>
        {label}
      </label>
    </li>
  );
}

import { findOptionsDifference } from 'utils/methods';

export default function ExchangeDifference({ productVariant, newItem }) {
  if (!productVariant || !newItem) {
    return null;
  }
  const differentProducts = areDifferentProducts({ productVariant, newItem });
  if (differentProducts) {
    return null;
    // Different products case:
    //For cases when product variant object is available
    //and no attribute different is needed to be shown
  }
  //
  /*
    This function returns an object with all the differenes in options
     format:
      {
        color: [oldColor, newColor],
        size: [oldSize, newSize]
      }
  */
  const optionDifference = findOptionsDifference(
    productVariant.option_values || productVariant.optionValues,
    newItem.option_values || newItem.optionValues
  );

  let text = '';
  // Difference may be none if the replacement is with the same variant
  if (!Object.keys(optionDifference).length) {
    text = 'Exchange with same item';
  } else {
    // example: Exchange with Size M
    // example: Exchange with Size L and Color Navy Blue
    text = Object.keys(optionDifference).reduce((str, key, index) => {
      const conjunction = index !== 0 ? 'and ' : '';
      // All spaces are intentional
      if (!optionDifference[key][1]) {
        return str;
      }
      return str + ` ${conjunction} ${key} ${optionDifference[key][1]}`;
    }, 'Exchange with');
  }
  return text;
}

const areDifferentProducts = ({ productVariant, newItem }) => {
  try {
    const differentProducts =
      (productVariant.product &&
        newItem.product &&
        productVariant.product.id !== newItem.product.id) ||
      (productVariant.product_id && // product_id exists when data comes from API
        newItem.product_id &&
        productVariant.product_id !== newItem.product_id);

    return differentProducts;
  } catch (e) {
    return true;
  }
};

import React from 'react';

export default function Loader({ customClass = '' }) {
  return (
    <div className={`spinner ${customClass}`}>
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  );
}

import React, {
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';
import _get from 'lodash/get';

import { If } from 'utils';
import Button from 'components/Button';
import { AuthContext } from 'context/AuthContext';
import { Input, AutoLogin } from './components';
import { useInput } from './hooks';
import {
  getDefaultInputIds,
  validateForm,
  canAutoLogin,
  getButtonInfo
} from '../../methods';
import { loginToPortal } from '../../services';
import { INPUT_ID } from 'views/Login/stubs';

const MAX_RETRY_COUNT = 3;

export default function Form() {
  const { search } = useLocation();

  // 👇 extract allowPhoneLogin from AuthContext when we plan to support phone number login
  const { login, getStoreDetails, setInputLogin } = useContext(AuthContext);
  const storeInfo = useRef(getStoreDetails()).current;
  const buttonText = useRef(getButtonInfo()).current;

  const [inputIds, setInputIds] = useState(getDefaultInputIds(search));
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  const [inputOneId, inputTwoId] = inputIds;
  const inputOneData = useInput(inputOneId);
  const inputTwoData = useInput(inputTwoId, storeInfo);

  const [showEmailInput, setShowEmailInput] = useState(false);
  const confirmEmailData = useInput(INPUT_ID.EMAIL);

  const tryLogin = useMemo(() => canAutoLogin(search), [search]);
  const [showForm, setShowForm] = useState(!tryLogin);
  const showAutoLogin = tryLogin && !showForm;

  useEffect(() => {
    setInputLogin(inputIds);
  }, []);

  useEffect(() => {
    if (tryLogin && retryCount < MAX_RETRY_COUNT) {
      loginUser(() => setRetryCount(prevCount => prevCount + 1));
    }
  }, [tryLogin, retryCount]);

  useEffect(() => {
    if (retryCount >= MAX_RETRY_COUNT) {
      onManualLogin();
      onCancelProcessing();
    }
  }, [retryCount]);

  const loginUser = (postRequestCallback = () => {}) => {
    const data = [inputOneData, inputTwoData];

    const _inputIds = [
      ...inputIds,
      ...(showEmailInput ? [INPUT_ID.EMAIL] : [])
    ];
    const _data = [...data, ...(showEmailInput ? [confirmEmailData] : [])];

    const errors = validateForm(_data);
    const inputOneError = _get(errors, '[0]', '');
    const inputTwoError = _get(errors, '[1]', '');
    const inputThreeError = _get(errors, '[2]', '');
    const hasError =
      !!inputOneError ||
      !!inputTwoError ||
      (showEmailInput && !!inputThreeError);

    if (hasError) {
      if (inputOneError) {
        const onUpdateError = _get(inputOneData, 'onUpdateError', () => {});
        onUpdateError(inputOneError);
      }
      if (inputTwoError) {
        const onUpdateError = _get(inputTwoData, 'onUpdateError', () => {});
        onUpdateError(inputTwoError);
      }
      if (showEmailInput && inputThreeError) {
        const onUpdateError = _get(confirmEmailData, 'onUpdateError', () => {});
        onUpdateError(inputThreeError);
      }
    } else {
      setIsProcessing(true);
      loginToPortal(
        { _inputIds, _data },
        data => {
          if (_inputIds.includes(INPUT_ID.PHONE)) {
            const emailRequiredForAuth = _get(data, 'email_required', false);
            if (emailRequiredForAuth) {
              setShowEmailInput(true);
            } else {
              login(data);
            }
          } else {
            login(data);
          }
        },
        msg => setErrorMessage(msg),
        postRequestCallback
      );
    }
  };

  const onCancelProcessing = useCallback(() => setIsProcessing(false), []);

  const initiateLogin = e => {
    e.preventDefault();
    loginUser(onCancelProcessing);
  };

  const onManualLogin = () => setShowForm(true);

  const onToggleInput = index => to => {
    if (!to) return;
    setInputIds(ids => {
      ids[index] = to;
      return [...ids];
    });
  };

  return (
    <>
      <AutoLogin show={showAutoLogin} onManualLogin={onManualLogin} />
      {showForm && (
        <>
          <form className="LoginForm" onSubmit={initiateLogin}>
            <Input
              data={inputOneData}
              disabled={isProcessing || showEmailInput}
              onToggle={onToggleInput(0)}
            />
            <Input
              data={inputTwoData}
              disabled={isProcessing || showEmailInput}
              onToggle={onToggleInput(1)}
            />
            <If test={showEmailInput}>
              <Input
                data={confirmEmailData}
                disabled={isProcessing}
                toggleDisabled
              />
            </If>
            <Button
              type="submit"
              isLoading={isProcessing}
              className="LoginForm__button"
            >
              {buttonText}
            </Button>
          </form>
          <Alert
            color="danger"
            isOpen={!!errorMessage && !isProcessing}
            className="LoginForm__formError"
          >
            {errorMessage}
          </Alert>
        </>
      )}
    </>
  );
}

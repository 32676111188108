import React from 'react';

import { Loader } from 'components';

export default function Loading(props) {
  if (props.loading) {
    return <Loader loading={true} {...props} />;
  }

  return props.children;
}

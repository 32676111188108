import _get from 'lodash/get';

import { INPUT_ID } from '../stubs';

export default function getLoginPayload(type, data) {
  const params = {};
  const valueOne = _get(data, '[0].value');
  const valueTwo = _get(data, '[1].value');
  const valueThree = _get(data, '[2].value');
  const valueFour = _get(data, '[3].value');

  let retailer = _get(window, 'retailer', '');
  if (retailer) {
    retailer = `?retailer=${retailer}`;
  } else {
    params.domain = _get(window, 'location.hostname', '');
  }

  switch (type) {
    case `${INPUT_ID.EMAIL} + ${INPUT_ID.ORDER}`:
      return {
        apiUrl: `/auth/customer/emailOrderId/${retailer}`,
        params: { ...params, email: valueOne, retailerOrderId: valueTwo }
      };
    case `${INPUT_ID.EMAIL} + ${INPUT_ID.ZIP}`:
      return {
        apiUrl: `/auth/customer/emailLastOrderZipcode/${retailer}`,
        params: { ...params, email: valueOne, lastOrderZipcode: valueTwo }
      };
    case `${INPUT_ID.PHONE} + ${INPUT_ID.ORDER}`:
      return {
        apiUrl: `/auth/customer/phoneOrderId/${retailer}`,
        params: { ...params, phone: valueOne, retailerOrderId: valueTwo }
      };
    case `${INPUT_ID.PHONE} + ${INPUT_ID.ORDER} + ${INPUT_ID.EMAIL}`:
      return {
        apiUrl: `/auth/customer/phoneOrderId/${retailer}`,
        params: {
          ...params,
          phone: valueOne,
          retailerOrderId: valueTwo,
          email: valueThree
        }
      };
    case `${INPUT_ID.PHONE} + ${INPUT_ID.ZIP}`:
      return {
        apiUrl: `/auth/customer/phoneLastOrderZipcode/${retailer}`,
        params: { ...params, phone: valueOne, lastOrderZipcode: valueTwo }
      };
    case `${INPUT_ID.PHONE} + ${INPUT_ID.ZIP} + ${INPUT_ID.EMAIL}`:
      return {
        apiUrl: `/auth/customer/phoneLastOrderZipcode/${retailer}`,
        params: {
          ...params,
          phone: valueOne,
          lastOrderZipcode: valueTwo,
          email: valueThree
        }
      };
    case `${INPUT_ID.ORDER} + ${INPUT_ID.GIFT_ZIP}`:
      return {
        apiUrl: `/auth/customer/orderZipCode/${retailer}`,
        params: { ...params, order_number: valueOne, zip_code: valueTwo }
      };
    case `${INPUT_ID.ORDER} + ${INPUT_ID.GIFT_ZIP} + ${INPUT_ID.EMAIL}`:
      return {
        apiUrl: `/auth/customer/orderZipCode/${retailer}`,
        params: {
          ...params,
          order_number: valueOne,
          zip_code: valueTwo,
          email: valueThree
        }
      };
    case `${INPUT_ID.FIRST_NAME} + ${INPUT_ID.LAST_NAME} + ${INPUT_ID.EMAIL} + ${INPUT_ID.ORDER}`:
      return {
        apiUrl: `/auth/customer/giftOrderTag/${retailer}`,
        params: {
          ...params,
          first_name: valueOne,
          last_name: valueTwo,
          email: valueThree,
          order_number: valueFour
        }
      };
    default:
      return null;
  }
}

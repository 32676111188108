import React from 'react';

import { Loader } from 'components';

export default function AutoLogin({ show = false, onManualLogin = () => {} }) {
  if (!show) return null;

  return (
    <div className="auto-login">
      <div className="auto-login__content">
        <Loader customClass="auto-login__content--loader" />
        <p className="auto-login__content--msg">
          Please wait while we log you in to our Returns Portal.
          <br />
          If the page doesn’t automatically redirect you, click{' '}
          <button
            className="auto-login__content--force-button"
            onClick={onManualLogin}
          >
            here
          </button>
        </p>
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import rabbitImage from 'images/rr-illustractions/chill-on-beach.png';

export default function Retailer404({ children = null }) {
  const { hasError, errorObject } = useContext(AuthContext);

  const source = _get(errorObject, 'source', '');
  const statusCode = _get(errorObject, 'statusCode', '');

  if (hasError && source === 'storeProperties' && statusCode === 404) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>You might’ve taken a wrong turn</h1>
          <p>Oops! We could not find the page you were looking for.</p>
          <p>
            You tried to visit: <span>{window.location.host}</span>
          </p>
        </div>
        <div className={styles.image}>
          <img src={rabbitImage} alt="rabbit" />
        </div>
      </div>
    );
  }

  return <div className="mainWrapper">{children}</div>;
}

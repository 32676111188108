import React from 'react';

import { ReactComponent as EasyExchange } from 'images/icons/new/exchanges.svg';
import { ReactComponent as FasterRefunds } from 'images/icons/new/refunds.svg';
import { ReactComponent as RealtimeTracking } from 'images/icons/new/tracking-big.svg';

const BENEFITS = [
  { img: <FasterRefunds />, label: 'FASTER REFUNDS' },
  { img: <RealtimeTracking />, label: 'REAL-TIME TRACKING' }
];

const ONLY_RETURN = {
  serviceCopies: {
    ORDER_LIST_HEADER: 'Please select an order to start return',
    LOGIN_HEADING: 'Returns Center',
    SERVICEABLE_ITEMS_HEADING: 'What would you like to return?',
    REASONS_HEADING: 'Why would you like to return?',
    ORDER_SERVICE_CTA: 'Return Items',
    SERVICEABILITY_NOTICE: 'Eligible for a return until',
    REVIEW_SCREEN_ALERT: '',
    ORDER_LIST_SUBHEADER:
      'Please select an order to start your return. If you want to see the service requests you have already placed, please select the order and get to the Already Serviced section.'
  },
  aspenBenefits: BENEFITS
};

// const ONLY_EXCHANGE = {
//   serviceCopies: {
//     ORDER_LIST_HEADER: 'Please select an order to start exchange',
//     LOGIN_HEADING: 'Exchanges Center',
//     SERVICEABLE_ITEMS_HEADING: 'What would you like to exchange?',
//     REASONS_HEADING: 'Why would you want to exchange?',
//     ORDER_SERVICE_CTA: 'Exchange Items',
//     SERVICEABILITY_NOTICE: 'Eligible for a exchange until',
//     REVIEW_SCREEN_ALERT:
//       "Your Exchange item, will be shipped, after we receive and verify your returned item. You'll be issued a new order number and shipment tracking within 7 business days after the return is received.",
//     ORDER_LIST_SUBHEADER:
//       'Please select an order to start your exchange. If you want to see the service requests you have already placed, please select the order and go to the Already Serviced section.'
//   },
//   aspenBenefits: [
//     { img: <EasyExchange />, label: 'EASY EXCHANGES' },
//     ...BENEFITS
//   ]
// };

const RETURN_AND_EXCHANGE = {
  serviceCopies: {
    ORDER_LIST_HEADER: 'Please select an order to start return or exchange',
    LOGIN_HEADING: 'Returns and Exchanges',
    SERVICEABLE_ITEMS_HEADING: 'What would you like to exchange or return?',
    REASONS_HEADING: 'Why would you like to exchange or return?',
    ORDER_SERVICE_CTA: 'Exchange or Return Items',
    SERVICEABILITY_NOTICE: 'Eligible for a exchange or return until',
    REVIEW_SCREEN_ALERT:
      "Your Exchange item (if requested), will be shipped, after we receive and verify your returned item. You'll be issued a new order number and shipment tracking within 7 business days after the return is received.",
    ORDER_LIST_SUBHEADER:
      'Please select an order to start your return or exchanges. If you want to see the service requests you have already placed, please select the order and get to the Already Serviced section.'
  },
  aspenBenefits: [
    { img: <EasyExchange />, label: 'EASY EXCHANGES' },
    ...BENEFITS
  ]
};

export {
  RETURN_AND_EXCHANGE,
  // ONLY_EXCHANGE,
  ONLY_RETURN
};

import get from 'lodash/get';

export default function extractIDsFromData(data, path) {
  if (!data || !path) {
    return [];
  }
  // Make into an array
  if (!Array.isArray(data) && typeof data === 'object') {
    data = [data];
  }
  return data.reduce((arr, dataElement) => {
    const newIDs = get(dataElement, path);
    // Don't want to add anything else
    if (typeof newIDs === 'string') {
      return [...arr, newIDs];
    }
    if (!Array.isArray(newIDs)) {
      return arr;
    }
    return [...arr, ...newIDs];
  }, []);
}

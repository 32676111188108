import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './index.module.scss';

export default function Button({
  linkTo = '',
  onClick = () => {},
  isLoading = false,
  className = '',
  children = null,
  theme = Theme.PRIMARY,
  preventDefaultHover = false,
  ...restHtmlProps
}) {
  const wrapClassName = cn(styles.action, styles[`action__${theme}`], {
    [styles.noHover]: preventDefaultHover,
    [className]: !!className
  });
  const spinner = (
    <i className={cn('fa fa-spinner', styles.spinner)} aria-hidden="true"></i>
  );
  return linkTo ? (
    <Link to={linkTo} className={wrapClassName} {...restHtmlProps}>
      {isLoading ? spinner : children}
    </Link>
  ) : (
    <button
      onClick={!isLoading ? onClick : null}
      className={wrapClassName}
      {...restHtmlProps}
    >
      {isLoading ? spinner : children}
    </button>
  );
}

export const Theme = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INLINE: 'inline'
};

import { loadDynamicScript } from '../extra';
import isHoneylove from './isHoneylove';

const isHoneyloveSite = isHoneylove();

const SCRIPTS = [
  {
    attach: isHoneyloveSite,
    id: 'ze-snippet',
    url:
      'https://static.zdassets.com/ekr/snippet.js?key=0b328631-5ba7-4254-b0c0-a0b2303a7ec0'
  },
  {
    attach: isHoneyloveSite,
    id: 'honeylove-solvvy',
    url: 'https://cdn.solvvy.com/deflect/customization/honeylove/solvvy.js'
  }
];

export default function attachCustomScripts() {
  SCRIPTS.forEach(({ attach, id, url }) => {
    if (attach) {
      loadDynamicScript({ id, url });
    }
  });
}

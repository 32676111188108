import React, { Component } from 'react';
class Loader extends Component {
  render() {
    const customClass = this.props.className ? this.props.className : '';
    if (this.props.loading) {
      return (
        <div className={customClass}>
          <div className="spinner-new" />
        </div>
      );
    }
    if (this.props.children) {
      return this.props.children;
    }
    return null;
  }
}
export default Loader;

import React, { useMemo } from 'react';

import classNames from 'classnames';
import useDynamicText from './hooks/useDynamicTextColor';

const DynamicTextColor = ({
  opacity = 1,
  content = null,
  fontSize = '14px',
  containerClassname = ''
}) => {
  const [textColor, backgroundColor] = useDynamicText('primary_color', opacity);

  const containerStyle = useMemo(
    () => ({
      color: textColor,
      backgroundColor: backgroundColor,
      fontSize: fontSize,
      fill: textColor
    }),
    [textColor, backgroundColor, fontSize]
  );

  const wrapperClassname = classNames({
    [containerClassname]: !!containerClassname
  });

  return (
    <div style={containerStyle} className={wrapperClassname}>
      {content}
    </div>
  );
};

export default DynamicTextColor;

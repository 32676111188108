import { Ret_Exc_Status, entityTypeChoices } from 'utils/extra/DICTIONARY';

import get from 'lodash/get';

export default function humanFriendlyRetexStatus(status, options) {
  let text = '';
  let fromWhom = 'from you';
  // Adding Context of who the action is required from in the Service Request status
  // This bit is completely optional - controlled by options object as below:
  if (options && options.userData && options.customerInfo) {
    const userEmail = get(options, 'userData.email');
    const serviceRequestCustomerEmail = get(options, 'customerInfo.email');
    let serviceRequestCustomerName = get(options, 'customerInfo.name') || '';
    serviceRequestCustomerName = serviceRequestCustomerName.split(' ')[0]; // Just need first name
    if (
      userEmail &&
      serviceRequestCustomerEmail &&
      serviceRequestCustomerName &&
      userEmail !== serviceRequestCustomerEmail
    ) {
      fromWhom = `from ${serviceRequestCustomerName}`;
    }
  }
  switch (status) {
    case 'AWAITING_STORE_AUTHORIZATION': {
      text = `Waiting to receive items ${fromWhom} at our store`;
      break;
    }
    case 'AWAITING_QUALIFICATION_APPROVAL': {
      text = `Awaiting approval by our team`;
      break;
    }
    case 'AWAITING_DELIVERY': {
      text = `Waiting to receive items ${fromWhom}`;
      break;
    }
    case 'AWAITING_WH_AUTHORIZATION': {
      text = 'Awaiting package delivery or warehouse approval';
      break;
    }
    case 'WH_AUTHORIZATION_REJECTED': {
      // Formatting the service Request entity (Exchange or Return)
      const entity = get(options, 'serviceRequest.entity_type');
      let serviceType = 'Request';
      if (entity) {
        serviceType = entityTypeChoices(entity);
      }
      text = `${serviceType} rejected`;
      // Converting text to proper case
      text = text.trim();
      text = text.charAt(0).toUpperCase() + text.slice(1);
      break;
    }
    default: {
      text = Ret_Exc_Status(status);
    }
  }
  return text;
}

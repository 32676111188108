import _get from 'lodash/get';

import { INPUT_ID } from '../stubs';

export default function getFormInputData(type = '', metadata = {}) {
  switch (type) {
    case INPUT_ID.EMAIL: {
      return {
        id: INPUT_ID.EMAIL,
        label: 'E-mail Address',
        type: 'email',
        placeholder: 'Please enter your e-mail address',
        toggleInfo: {
          to: INPUT_ID.PHONE,
          text: 'Use phone number'
        }
      };
    }
    case INPUT_ID.PHONE: {
      return {
        id: INPUT_ID.PHONE,
        label: 'Phone Number',
        type: 'tel',
        placeholder: 'Please enter your phone number',
        toggleInfo: {
          to: INPUT_ID.EMAIL,
          text: 'Use E-mail address'
        }
      };
    }
    case INPUT_ID.ORDER: {
      const example = _get(metadata, 'example', null);
      const helpText = _get(metadata, 'helpText', null);
      const regex = _get(metadata, 'nameRegEx', null);
      return {
        id: INPUT_ID.ORDER,
        label: 'Order Number',
        type: 'text',
        placeholder: example
          ? `Order Number e.g. ${example}`
          : 'Please enter your order number',
        suggestionData: { helpText, regex },
        toggleInfo: {
          to: INPUT_ID.ZIP,
          text: 'Use Zipcode'
        }
      };
    }
    case INPUT_ID.ZIP: {
      return {
        id: INPUT_ID.ZIP,
        label: 'Shipping Zipcode',
        type: 'text',
        placeholder: 'Zipcode of your last order',
        toggleInfo: {
          to: INPUT_ID.ORDER,
          text: 'Use Order Number'
        }
      };
    }
    case INPUT_ID.GIFT_ZIP: {
      return {
        id: INPUT_ID.GIFT_ZIP,
        label: 'Shipping Zipcode',
        type: 'text',
        placeholder: 'Zipcode of your gifted order',
        toggleInfo: {}
      };
    }
    case INPUT_ID.FIRST_NAME: {
      return {
        id: INPUT_ID.FIRST_NAME,
        label: 'First Name',
        type: 'text',
        placeholder: 'Enter your first name',
        toggleInfo: {}
      };
    }
    case INPUT_ID.LAST_NAME: {
      return {
        id: INPUT_ID.LAST_NAME,
        label: 'Last Name',
        type: 'text',
        placeholder: 'Enter your last name',
        toggleInfo: {}
      };
    }
    default:
      return null;
  }
}

import axios from 'axios';
import user from './../extra/user';

function deleteRequest(apiUrl, payload, options) {
  const token = user.getToken();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const url = options && options.test ? apiUrl : baseURL + apiUrl;
  let headers = {
    headers: {
      Authorization: 'Token ' + token
    }
  };
  if (options && options.noToken) {
    delete headers.headers;
  }
  return axios.delete(url, { params: payload.params, ...headers });
}

export default deleteRequest;

import { get, logError } from 'utils';
import { getUrlParameter } from 'utils/methods';

export default function getTrackingPageData(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const location = window.location;
  const domain =
    getUrlParameter(location.search, 'domain') || location.hostname;
  get(
    '/shipment-tracking/tracking/',
    { params: { ...params, domain } },
    { noToken: true }
  )
    .then(({ data }) => onSuccess(data))
    .catch(err => {
      onError(err);
      logError(err, {
        customMessage: 'Unable to load shipment updates'
      });
    })
    .finally(onFinally);
}

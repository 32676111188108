import extractIDsFromData from './extractIDsFromData';
import findOptionsDifference from './findOptionsDifference';
import getServiceRequestStatusCategory from './getServiceRequestStatusCategory';
import trimSpacesFromObject from './trimSpacesFromObject';
import getUrlParameter from './getUrlParameter';
import formatStoreProperties from './formatStoreProperties';
import getStoreDetailsObject from './getStoreDetailsObject';
import hasAddressInformation from './hasAddressInformation';
import { collectInputErrors, getTrimedValue } from './customerInfoUpdate';
import getProductDisplayPrice from './getProductDisplayPrice';
import validateCoordinates from './validateCoordinates';
import showAspenBenefits from './showAspenBenefits';
import showPoweredByAspen from './showPoweredByAspen';
import showRestockingFee from './showRestockingFee';
import loadJsCssFile from './loadJsCssFile';
import showCovidBanner from './showCovidBanner';
import showRecommendations from './showRecommendations';
import isValidEmail from './isValidEmail';
// import validateOsCreationSteps from "./validateOsCreationSteps";

export {
  // validate: validateOsCreationSteps,
  extractIDsFromData,
  findOptionsDifference,
  getServiceRequestStatusCategory,
  trimSpacesFromObject,
  getUrlParameter,
  formatStoreProperties,
  getStoreDetailsObject,
  hasAddressInformation,
  collectInputErrors,
  getTrimedValue,
  getProductDisplayPrice,
  validateCoordinates,
  showAspenBenefits,
  showPoweredByAspen,
  showRestockingFee,
  loadJsCssFile,
  showCovidBanner,
  showRecommendations,
  isValidEmail
};

import React from 'react';
import { AuthConsumer } from 'context/AuthContext';
// Store connector
const connectStoreDetails = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => (
        <ConnectedComponent
          {...props}
          appType={context.appType}
          getStoreDetails={context.getStoreDetails}
        />
      )}
    </AuthConsumer>
  );
};

const connectUserData = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => <ConnectedComponent {...props} userData={context.userData} />}
    </AuthConsumer>
  );
};

const connectError = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => (
        <ConnectedComponent
          {...props}
          generateError={context.appError}
          hasError={context.hasError}
          errorObject={context.errorObject}
        />
      )}
    </AuthConsumer>
  );
};

const connectLogout = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => <ConnectedComponent {...props} logout={context.logout} />}
    </AuthConsumer>
  );
};

const connectCopies = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => (
        <ConnectedComponent {...props} copies={context.serviceCopies} />
      )}
    </AuthConsumer>
  );
};

const connectCanCreateRequest = ConnectedComponent => {
  return props => (
    <AuthConsumer>
      {context => (
        <ConnectedComponent
          {...props}
          canCreateRequest={context.canCreateRequest}
        />
      )}
    </AuthConsumer>
  );
};

export {
  connectStoreDetails,
  connectUserData,
  connectError,
  connectLogout,
  connectCopies,
  connectCanCreateRequest
};

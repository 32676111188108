import React, { useMemo } from 'react';
import _get from 'lodash/get';

import { createAddressLine } from 'views/ShipmentTrackingApp/methods';

export default function Address({
  title = '',
  className = '',
  data = {},
  compact = false
}) {
  const address1 = _get(data, 'address_1', '');
  const address2 = _get(data, 'address_2', '');
  const city = _get(data, 'city', '');
  const zip = _get(data, 'zip', '');
  const province = _get(data, 'province', '');
  const country = _get(data, 'country', '');

  const addressLine1 = useMemo(() => {
    return createAddressLine([address1, address2]);
  }, [address1, address2]);

  const addressLine2 = useMemo(() => {
    return createAddressLine([city, province]);
  }, [city, province]);

  const addressLine3 = useMemo(() => {
    return createAddressLine([country, zip], ' - ');
  }, [country, zip]);

  return (
    <div className={className}>
      {title && <p>{title}:</p>}
      {compact ? (
        <>
          <span>{addressLine2}</span>
          <span>{country}</span>
        </>
      ) : (
        <>
          <span>{addressLine1}</span>
          <span>{addressLine2}</span>
          <span>{addressLine3}</span>
        </>
      )}
    </div>
  );
}

import axios from 'axios';
import async from 'async';
import _get from 'lodash/get';

import { getUrlParameter } from 'utils/methods';
import loadCSSVariablesPolyfill from 'config/polyfills/cssVariables';

// Fetch store properties for retailer
export default function fetchStoreProperties(success, error) {
  // Re-tries 2 more times
  let searchParams = '';
  const location = window.location.search;
  const retailer = (getUrlParameter(location, 'retailer') || '').toLowerCase();
  if (retailer && retailer.length) {
    searchParams = `?retailer=${retailer}`;
  } else {
    searchParams = `?domain=${(window.location || {}).hostname}`;
  }
  const url = `${process.env.REACT_APP_BASE_URL}/customer/storeProperties/${searchParams}`;
  // let testUrl = "https://api.myjson.com/bins/riy5j";

  const fetchUpdateStoreProperties = async callback => {
    try {
      const data = await axios.get(url);
      callback(null, data);
    } catch (e) {
      callback(e, null);
    }
  };

  async.retry(
    {
      times: 3,
      interval: 3000,
      errorFilter: err => _get(err, 'statusCode') !== 404
    },
    fetchUpdateStoreProperties,
    function(err, result) {
      if (err) {
        const errorObject = new Error(
          `GET Store properties Failed: ${searchParams}`
        );
        errorObject.source = 'storeProperties';
        errorObject.searchParams = searchParams;
        errorObject.statusCode = _get(err, 'statusCode');
        error(errorObject);
      } else {
        const data = _get(result, 'data');
        if (!data) {
          throw new Error('No data to process');
        }
        loadCSSVariablesPolyfill(JSON.stringify(data));
        success(JSON.stringify(data));
      }
    }
  );
}

import React from 'react';
import error from 'images/icons/error.svg';
import success from 'images/icons/success.svg';

const types = {
  success,
  error,
  info: 'info',
  warn: 'warning'
};
const checkType = type => {
  return type === 'success' || type === 'error';
};

const ToastMsg = ({ msg, type = '' }) => {
  return (
    <div className="customToast__screen">
      {checkType(type) ? <img src={types[type]} alt={types[type]} /> : null}
      <div className="customToast__screen-text">{msg}</div>
    </div>
  );
};

export default ToastMsg;

import axios from 'axios';
import _get from 'lodash/get';

import user from './../extra/user';

export default function get(apiUrl = '', payload = {}, options = {}) {
  if (!apiUrl) {
    throw new Error('API Endpoint was not provided');
  }

  const token = user.getToken();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const params = _get(payload, 'params', {});
  const isTest = _get(options, 'test', false);
  const noTokenNeeded = _get(options, 'noToken', false);
  const axiosConfig = _get(options, 'axiosConfig', {});

  const url = isTest ? apiUrl : baseURL + apiUrl;

  const headers = noTokenNeeded ? {} : { Authorization: `Token ${token}` };

  return axios.get(url, { params, headers, ...axiosConfig });
}

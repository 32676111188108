/**
 * format Price in standard format of $40.00 (two decimal places)
 * @param  {string} price
 * @param  {string} currency
 * @param {Object} options
 * @return {string}
 */
import { logError } from 'utils/extra';
import { findCurrency } from 'utils/extra/DICTIONARY';

export default function(price, currency, options) {
  let formattedCurrency = findCurrency(currency) || '';
  if (options === undefined) {
    options = { absolute: true };
  }
  let { absolute } = options;
  if (!price) {
    return `${formattedCurrency}0.00`;
  }
  if (currency === undefined || currency === null) {
    logError('Currency comes either empty,null or undefined');
    return `${(Math.round(price * 100) / 100).toFixed(2)}`;
  }
  if (absolute) {
    return `${formattedCurrency}${(
      Math.round(Math.abs(price * 100)) / 100
    ).toFixed(2)}`;
  }
  if (currency) {
    return `${formattedCurrency}${(Math.round(price * 100) / 100).toFixed(2)}`;
  }
  return `${(Math.round(price * 100) / 100).toFixed(2)}`;
}

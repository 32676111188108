const VALUES = {
  ORDER_NAME: 'order_name',
  TRACKING_NUMBER: 'tracking_number'
};

const LABELS = {
  [VALUES.ORDER_NAME]: 'Order Name',
  [VALUES.TRACKING_NUMBER]: 'Tracking Number'
};

const OPTIONS = [
  { label: 'Order Number', value: VALUES.ORDER_NAME },
  { label: 'Tracking Number', value: VALUES.TRACKING_NUMBER }
];

export { OPTIONS, VALUES, LABELS };

import * as Sentry from '@sentry/browser';
// import * as mixpanel from 'mixpanel-browser';

import { identifyUser } from 'utils/analytics';

export default function() {
  const sentryApp = process.env.REACT_APP_SENTRY_APP_ID;
  const environment = process.env.REACT_APP_MODE;
  // const mixpanelApp = process.env.REACT_APP_MIXPANEL_ID;

  if (sentryApp) {
    Sentry.init({
      dsn: sentryApp,
      environment,
      release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_CURRENT_GIT_SHA}`
    });
  }

  // if (mixpanelApp) {
  //   mixpanel.init(mixpanelApp);
  // }
  identifyUser();
}

import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import _get from 'lodash/get';

import Button from 'components/Button';
import { AuthContext } from 'context/AuthContext';

export default function OrderNameModal({ isOpen, toggle }) {
  const { getStoreDetails } = useContext(AuthContext);

  const { name, storeOrderText } = getStoreDetails();
  const image = _get(
    storeOrderText,
    'nameImage',
    'https://res.cloudinary.com/supply-ai/image/upload/v1559635098/retailers/default/zenwear-emall-screenshot.jpg'
  );

  return (
    <Modal
      className="OrderNameModal modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Where is my order number?</ModalHeader>
      <ModalBody>
        <p className="OrderNameModal__text">
          You can easily find your Order Number if you search your email for the{' '}
          <strong>{name}</strong> order confirmation. Follow the areas
          highlighted in the image below to find the Order Number and start your
          Service Request.
        </p>
        <div className="OrderNameModal__imageWrapper">
          <img
            src={image}
            alt="Screenshot of Order Confirmation Email sent when order is placed with the order number area highlighted"
            className="OrderNameModal__image"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} className="OrderNameModal__button">
          Okay, Got it!
        </Button>
      </ModalFooter>
    </Modal>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function QrLabelLinks() {
  return (
    <div className="qrLabelLinks">
      <p>
        To find the locations where you can drop-off your package, please click{' '}
        <Link to="/qr-shipping-label" target="_blank">
          here
        </Link>
      </p>
      <p>
        If you change your mind about QR code and want to print a label instead,
        click{' '}
        <a
          href="https://tools.usps.com/label-broker.htm"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
      </p>
    </div>
  );
}

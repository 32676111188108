import React, { createContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { OnlineCheck, MaintenanceBreak } from 'components';

export const AppContext = createContext({ isOnline: true });

export function AppProvider({ children = null }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
  }, []);

  return (
    <AppContext.Provider value={{ isOnline }}>
      <OnlineCheck />
      <ToastContainer />
      <MaintenanceBreak>{children}</MaintenanceBreak>
    </AppContext.Provider>
  );
}

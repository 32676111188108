import React, { useState } from 'react';

import { logEvent } from 'utils/analytics';
import OrderNameModal from './OrderNameModal';
import { ReactComponent as HelpIcon } from 'images/icons/question-mark-rounded.svg';

export default function OrderNameHelper() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);

  const toggleModal = () => setIsModalOpen(isOpen => !isOpen);

  const handleClick = e => {
    // e.preventDefault();
    showModal();
    logEvent('Click on need help with order name');
  };

  return (
    <>
      <button type="button" className="OrderNameLabel" onClick={handleClick}>
        {/* <img src={helpIcon} alt="help icon" /> */}
        <HelpIcon />
      </button>
      <OrderNameModal isOpen={isModalOpen} toggle={toggleModal} />
    </>
  );
}

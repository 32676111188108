// import * as mixpanel from 'mixpanel-browser';

import { logError } from 'utils/extra';
export default function logEvent(text, props) {
  if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    console.log('Log Event: ', text);
  }
  // const mixpanelApp = process.env.REACT_APP_MIXPANEL_ID;
  // if (!mixpanelApp) {
  //   return;
  // }
  try {
    // mixpanel.track(text, props);
  } catch (e) {
    logError(e);
  }
}

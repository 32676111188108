import React from 'react';

const MultiSelectIcon = ({ selected }) => {
  let icon = '';

  if (!selected) icon = 'far fa-square';
  else icon = 'fas fa-check-square';

  return (
    <span className={`option-icon ${selected ? 'selected' : ''}`}>
      <i className={icon} />
    </span>
  );
};
export default MultiSelectIcon;

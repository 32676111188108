import React, { useEffect } from 'react';
import { useLottie } from 'lottie-react';
import cn from 'classnames';

import confettiAnimation from 'utils/lottie/confetti.json';
import styles from './index.module.scss';

export default function Confetties({
  loop = false,
  delay = 100,
  className = ''
}) {
  const { View, play, animationContainerRef } = useLottie(
    {
      animationData: confettiAnimation,
      className: cn(styles.confetti, className),
      autoplay: false,
      loop
    },
    { opacity: 0 }
  );

  useEffect(() => {
    if (play && animationContainerRef.current) {
      const timer = setTimeout(() => {
        animationContainerRef.current.style.opacity = 1;
        play();
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [delay, play, animationContainerRef]);

  return <>{View}</>;
}

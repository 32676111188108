import parallel from 'async/parallel';

import getTrackingPageConfig from './getTrackingPageConfig';
import getTrackingUpdates from './getTrackingUpdates';

export default function getTrackingData(
  params = {},
  onSuccess = () => {},
  onError = () => {}
) {
  parallel(
    {
      config: cb => {
        getTrackingPageConfig(
          params,
          data => cb(null, data),
          err => cb(err)
        );
      },
      updates: cb => {
        getTrackingUpdates(
          params,
          data => cb(null, data),
          err => cb(err)
        );
      }
    },
    (err, data) => {
      if (err) return onError(err);
      onSuccess(data);
    }
  );
}

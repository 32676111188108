import {
  activeServiceRequestChoices,
  rejectedServiceRequestChoices,
  approvedServiceRequestChoices
} from 'utils/extra/DICTIONARY';

/**
 * Get the category from dictionary of service request choices and return it in integer form
 * @param  {string} status serviceRequest status
 * @return {-1,0,1,null} Return category as an integer depending on it being positive, negative or neutral
 */
export default function getServiceRequestStatusCategory(status) {
  // Returning null if status is not provided so that the accompanying logic
  // doesn't use a else condition to evaluate the category but rather an else if
  if (!status) {
    return null;
  }
  if (activeServiceRequestChoices().indexOf(status) !== -1) {
    return 0;
  } else if (approvedServiceRequestChoices().indexOf(status) !== -1) {
    return 1;
  } else if (rejectedServiceRequestChoices().indexOf(status) !== -1) {
    return -1;
  }
}

import { useMemo, useEffect, useState } from 'react';

import { rgb } from 'wcag-contrast';
import Color from 'color';

const THEME = {
  primary_color: '--theme__primary--color',
  primary: '--theme__primary-btn--background-color',
  secondary: '--theme__secondary-btn--background-color'
};

const WHITE_COLOR = [255, 255, 255];
const DARK_COLOR = [12, 19, 26];

const useDynamicText = (theme, opacity = 1) => {
  const [textColor, setTextColor] = useState(Color(WHITE_COLOR).string());

  const primaryThemeColor = useMemo(() => {
    return getComputedStyle(document.body).getPropertyValue(THEME[theme]);
  }, [theme]);

  const [backgroundColor, setBackgroundColor] = useState(primaryThemeColor);

  // console.log('primaryThemeColor', primaryThemeColor);

  useEffect(() => {
    if (primaryThemeColor) {
      const _backgroundColor = Color(primaryThemeColor).alpha(opacity);
      const contrast = Number(
        rgb(_backgroundColor.array(), WHITE_COLOR).toFixed(2)
      );
      if (contrast < 4) {
        setTextColor(Color(DARK_COLOR).string());
      }
      setBackgroundColor(_backgroundColor.string());
    }
  }, [primaryThemeColor, opacity]);

  return [textColor, backgroundColor];
};

export default useDynamicText;

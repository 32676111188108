import axiosSetup from './axiosSetup';
import loggingAndAnalyticsSetup from './loggingAndAnalyticsSetup';
import loadPolyfills from 'config/polyfills';
import setupGoogleAnalytics from './setupGoogleAnalytics';

export default function() {
  // Load logrocket and sentry before polyfills so we can capture errors
  loggingAndAnalyticsSetup();
  loadPolyfills();
  axiosSetup();
  setupGoogleAnalytics();
}

// Function to loop over an object and trim spaces from all string value keys
export default function trimSpacesFromObject(obj) {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  const formattedObj = Object.assign({}, obj);
  for (const key in formattedObj) {
    if (
      formattedObj.hasOwnProperty(key) &&
      typeof formattedObj[key] === 'string'
    ) {
      formattedObj[key] = formattedObj[key].trim();
    }
  }
  return formattedObj;
}

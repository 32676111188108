import React, { useEffect, useRef, Suspense, lazy, useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';

import { getUrlParameter, showPoweredByAspen } from 'utils/methods';
import routes from 'dictionary/routes';
import { Loading } from 'components/Common';
import ErrorBoundary from './ErrorBoundary.js';
import { Header } from 'views/Templates/Store';
import { ReminderFeedback } from 'views/Other';
import { ErrorPage, NotFound } from 'views/Errors';
import {
  ConditionalRoute,
  ProtectedRoute,
  UnProtectedRoute,
  // GiftRoute,
  PublicRoute
} from './routeConfig';
import { AuthContext } from 'context/AuthContext';

ThemedStyleSheet.registerInterface(aphroditeInterface);

const Login = lazy(() => import('./../views/Login'));
const AccountOverview = lazy(() =>
  import('./../views/Orders/components/AccountOverview')
);
const OrderWrapper = lazy(() =>
  import('./../views/Orders/components/OrderWrapper')
);
const OrderService = lazy(() => import('./../views/OrderService'));
const ShipmentTracking = lazy(() => import('./../views/ShipmentTracking'));
// const CovidInstructions = lazy(() => import('./../views/Templates/COVID'));

const GiftReturnLogin = lazy(() => import('./../views/GiftReturnLogin'));

export default function Layout() {
  const { search, pathname } = useLocation();
  const showAspenBranding = useRef(showPoweredByAspen()).current;
  const { getStoreDetails } = useContext(AuthContext);

  const storeDetails = getStoreDetails();
  const isGiftReturnsEnabled = _get(storeDetails, 'allowGiftReturns', false);

  useEffect(() => {
    const retailer = getUrlParameter(search, 'retailer') || '';
    window.retailer = retailer.toLowerCase();
  }, [search]);

  if (_isEmpty(storeDetails)) {
    return <ErrorPage />;
  }
  return (
    <>
      <Header />
      <ErrorBoundary location={pathname}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <UnProtectedRoute exact path="/login" component={Login} />
            <UnProtectedRoute
              exact
              path="/gift-login"
              component={() => {
                if (!isGiftReturnsEnabled) return <Redirect to="/login" />;
                return <GiftReturnLogin />;
              }}
            />
            <ProtectedRoute
              path={routes.account}
              component={AccountOverview}
              hasFooter={true}
              hasAspenBranding={showAspenBranding}
            />
            <PublicRoute
              exact={true}
              strict={false}
              path="/tracking/:shipmentID"
              component={ShipmentTracking}
              hasAspenBranding={showAspenBranding}
            />
            {/* <PublicRoute
                  exact
                  path="/COVID-instructions"
                  component={CovidInstructions}
                  hasAspenBranding
                  covidDisclaimer
                /> */}
            <Redirect
              exact
              path={routes.orders}
              to={`${routes.account}/orders`}
            />
            <ProtectedRoute
              path={routes.orderDetails}
              component={OrderWrapper}
              hasFooter={true}
              hasAspenBranding={showAspenBranding}
            />
            <ProtectedRoute
              path="/orderService/"
              component={OrderService}
              hasFooter={true}
              hasAspenBranding={showAspenBranding}
            />
            {/* DO NOT change this route as it is used in reminder emails */}
            <Route
              exact
              path="/reminder-feedback"
              component={ReminderFeedback}
            />
            <Route exact path="/404" component={NotFound} />
            <ConditionalRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

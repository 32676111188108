import LogRocket from 'logrocket';

const BLOCKED_STORES_IDS = [
  'paragonfitwear',
  'wearnala',
  'cvlinens',
  'jordancraig',
  'springstepshoes',
  'lysse',
  'neveneyewear',
  'ninelineapparel',
  'brixtonllc',
  'lotusandluna'
];

export function isLogRocketDisabled() {
  const url = (window.location.href || '').toLowerCase();
  return BLOCKED_STORES_IDS.some(storeId => url.includes(storeId));
}

export default function logRocketInit() {
  const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;

  if (logRocketApp && !isLogRocketDisabled()) {
    LogRocket.init(logRocketApp, {
      console: {
        shouldAggregateConsoleErrors: true
      },
      release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_CURRENT_GIT_SHA}`
    });
  }
}

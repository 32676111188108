import qs from 'qs';
import _get from 'lodash/get';

export default function canAutoLogin(querySearchString = '') {
  const queryParams = qs.parse(querySearchString, { ignoreQueryPrefix: true });

  const email = _get(queryParams, 'email', '');
  const orderNumber = _get(queryParams, 'order_number', '');
  const canAutoLogin = !!email && !!orderNumber;

  return canAutoLogin;
}

import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { ShipmentContext } from 'views/ShipmentTrackingApp/context';

export default function Banner({
  id = '',
  className = '',
  bannerClassName = ''
}) {
  const { config, deviceType } = useContext(ShipmentContext);

  const data = _get(config, `${id}.${deviceType}`, {});
  const showBanner = _get(data, 'show', false);
  const bannerImgUrl = _get(data, 'image_url', '');
  const bannerLinkUrl = _get(data, 'link_url', '');
  const title = _get(data, 'title.text', '');
  const color = _get(data, 'title.color', '');
  const textAlign = _get(data, 'title.align', '');

  const containerClassName = useMemo(() => {
    return cn(styles.container, { [className]: !!className });
  }, [className]);

  const bannerContainerClassName = useMemo(() => {
    return cn(styles.banner, { [bannerClassName]: !!bannerClassName });
  }, [bannerClassName]);

  if (!showBanner || !bannerImgUrl) return null;

  return (
    <div className={containerClassName}>
      {title && (
        <p style={{ color, textAlign }} className={styles.title}>
          {title}
        </p>
      )}
      <a
        href={bannerLinkUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={bannerContainerClassName}
      >
        <div
          className={styles.imgWrapper}
          style={{
            backgroundImage: `url(${bannerImgUrl})`
          }}
        ></div>
        <img className={styles.bannerImg} src={bannerImgUrl} alt="banner" />
      </a>
    </div>
  );
}

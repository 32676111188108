//Add here more currencies format
const currency = {
  USD: '$',
  EUR: '€',
  CAD: 'C$'
};

const currencySymbol = val => {
  return currency[val] || val;
};

export default currencySymbol;

import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import { isLogRocketDisabled } from 'config/setup/logRocketInit';

const logMessage = message => {
  if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    console.log('Log Message: ', message);
  }

  if (!isLogRocketDisabled()) {
    LogRocket.captureMessage(message);
  }
  Sentry.captureMessage(message);
};
export default logMessage;

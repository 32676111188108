import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
// import * as mixpanel from 'mixpanel-browser';

import { localStore } from 'utils';
import { logError } from 'utils/extra';
import { tagSessionVideo } from 'utils/analytics';
import { isLogRocketDisabled } from 'config/setup/logRocketInit';

export default function identifyUser() {
  try {
    const retailer = localStore.get('retailer');
    let user = localStore.get('userData') || {};
    if (!user || typeof user !== 'string') {
      return;
    }
    user = JSON.parse(user);
    if (!user || !user.userId) {
      return;
    }
    const { userId, firstName, lastName } = user;
    const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;
    const sentryApp = process.env.REACT_APP_SENTRY_APP_ID;
    // const mixpanelApp = process.env.REACT_APP_MIXPANEL_ID;
    const userProperties = {
      $first_name: firstName,
      $last_name: lastName
    };
    // We use this to filter funnel mixpanel events by retailer
    if (retailer) {
      userProperties.retailer = retailer;
    }
    // if (mixpanelApp) {
    //   mixpanel.identify(userId);
    //   mixpanel.people.set(userProperties);
    // }
    if (logRocketApp && !isLogRocketDisabled()) {
      LogRocket.identify(userId, {
        name: `${firstName} ${lastName}`
      });
      tagSessionVideo(`Retailer: ${retailer}`);
    }
    if (sentryApp) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: userId,
          username: `${firstName} ${lastName}`
        });
      });
    }
  } catch (e) {
    logError(e);
  }
}

import React, { useContext } from 'react';
import { Alert } from 'reactstrap';

import { AppContext } from 'context/AppContext';

export default function OnlineCheck() {
  const { isOnline } = useContext(AppContext);

  if (isOnline) return null;

  return (
    <Alert color="danger" id="fixedOnlineAlert" className="fadeInUp">
      No internet connection. Try again when you are online.
    </Alert>
  );
}

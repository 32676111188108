import { get } from 'utils/extra';
import { logError } from 'utils';

function getQrLabelSupportedLocations(
  params = {},
  success = () => {},
  error = () => {},
  postRequestCb = () => {}
) {
  get('/customer/carrierFacility/', params, { noToken: true })
    .then(res => success(res.data))
    .catch(err => {
      const errMessage =
        'Unable to fetch the locations to serve QR label supported ';
      err.message = 'GET QR supportted locations';
      err.displayMessage = errMessage;
      logError(errMessage, err);
      error(err);
    })
    .finally(postRequestCb);
}
export default getQrLabelSupportedLocations;

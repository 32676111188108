import _get from 'lodash/get';
import _lowerCase from 'lodash/lowerCase';

import { post } from 'utils';
import { logError } from 'utils/extra';
import { logEvent } from 'utils/analytics';
import { trimSpacesFromObject } from 'utils/methods';
import { getLoginPayload } from '../methods';

export default function loginToPortal(
  { _inputIds = [], _data = [] },
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const type = _inputIds.join(' + ');
  const inputOneId = _lowerCase(_get(_inputIds, '[0]', ''));
  const inputTwoId = _lowerCase(_get(_inputIds, '[1]', ''));

  logEvent('Login: Submit', { method: type });

  const payload = getLoginPayload(type, _data);
  const apiUrl = _get(payload, 'apiUrl', '');
  const payloadParams = _get(payload, 'params', {});
  const params = trimSpacesFromObject(payloadParams);

  post(apiUrl, { params }, { noToken: true })
    .then(({ data }) => {
      logEvent('User Logged In', { method: type });
      onSuccess({ ...data, ...params });
    })
    .catch(error => {
      const statusCode = _get(error, 'statusCode', 0);
      let msg = '';
      if (statusCode === 401) {
        msg = `Please enter the correct ${inputOneId} and ${inputTwoId} of your last order`;
      } else {
        msg = 'Something went wrong. Please try again after sometime.';
        error.message = `POST: ${type} Login`;
        logError(error);
      }
      logEvent('Login Attempt Failed', { method: type, code: statusCode });
      onError(msg);
    })
    .finally(onFinally);
}

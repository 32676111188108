import React, { useContext } from 'react';
import cn from 'classnames';
import _get from 'lodash/get';

import { connectStoreDetails } from 'utils/connectors/AppConnectors';
import { If } from 'utils';
import { isSwetTailor, isHoneylove, isGeologie } from 'utils/retailer';
import { AuthContext } from 'context/AuthContext';
import { useMemo } from 'react';

function Footer({
  getStoreDetails = () => {},
  className = '',
  isMerchantSpecific = true
}) {
  const { isDemoApp = false } = useContext(AuthContext);

  const storeDetails = getStoreDetails();
  const phone = _get(storeDetails, 'retailerPhone', '');
  const email = _get(storeDetails, 'email', '');

  const hideContactNumber = isSwetTailor() || isGeologie();

  const containerClassName = cn(
    'Footer',
    'regular',
    'font-normal',
    'dark',
    className
  );

  const contactUsText = useMemo(
    () =>
      isDemoApp
        ? "Like what you see? Drop us a line and we'll get in touch:"
        : 'Contact Support:',
    [isDemoApp]
  );

  if (isMerchantSpecific) {
    if (isHoneylove()) {
      return (
        <HoneyloveFooter
          phone={phone}
          email={email}
          className={containerClassName}
        />
      );
    }
  }

  return (
    <p className={containerClassName}>
      <If test={email}>
        {contactUsText} <a href={`mailto:${email}`}>{email}</a>
      </If>
      <If test={!hideContactNumber && phone}>
        {' | '} <span>{phone}</span>
      </If>
    </p>
  );
}

const HoneyloveFooter = ({ phone = '', email = '', className = '' }) => {
  if (!phone && !email) return null;

  return (
    <p className={className}>
      <If test={phone}>
        Phone <span>{phone}</span>
      </If>
      <If test={email}>
        {' '}
        or drop us an email <a href={`mailto:${email}`}>{email}</a>
      </If>
      <br />
    </p>
  );
};

export default connectStoreDetails(Footer);

import React from 'react';

import { StrikedText } from 'components/Common';
import { formatPrice } from 'utils/formatters';

class OrderPrice extends React.Component {
  render() {
    const { currency, netPrice, price } = this.props;
    let netPriceFormatted = Number(netPrice);
    if (Number.isNaN(netPriceFormatted)) {
      return null;
    }
    if (!price || price <= netPrice) {
      // Note: Price is less than netPrice when taxes are excluded in price
      // So price + taxes = netPrice
      return <React.Fragment>{formatPrice(netPrice, currency)}</React.Fragment>;
    }
    return (
      <React.Fragment>
        <StrikedText>{formatPrice(price, currency)}</StrikedText>{' '}
        {formatPrice(netPrice, currency)}
      </React.Fragment>
    );
  }
}

export default OrderPrice;

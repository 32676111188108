import localStore from './localStore.js';

function isLoggedIn() {
  var authToken = localStore.get('authToken');
  if (!authToken) {
    logout();
    return false;
  }
  return true;
}

function logout() {
  localStore.clear('authToken');
  localStore.clear('userData');
  localStore.clear('retailer');
}

function getToken() {
  var authToken = localStore.get('authToken');

  if (authToken) return authToken;
  return null;
}

function set(data) {
  return localStore.put('userData', data);
}

function get() {
  const data = localStore.get('userData');
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
}

export default {
  isLoggedIn,
  logout,
  getToken,
  get,
  set
};

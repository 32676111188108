import React, { useContext, createContext } from 'react';
import cn from 'classnames';
import _isNumber from 'lodash/isNumber';
import styles from './index.module.scss';

const SkeletonContext = createContext({
  width: '100%',
  height: '14px',
  animate: true,
  className: '',
  color: '#eeeeee',
  highlightColor: '#e3e3e3'
});

export function SkeletonTheme({
  opacity = 1,
  width = '100%',
  height = '14px',
  animate = true,
  className = '',
  color = '#eeeeee',
  highlightColor = '#e3e3e3',
  children
}) {
  return (
    <SkeletonContext.Provider
      value={{
        color,
        width,
        opacity,
        height,
        animate,
        className,
        highlightColor
      }}
    >
      {children}
    </SkeletonContext.Provider>
  );
}

export default function Skeleton({
  width = '',
  height = '',
  opacity = 1,
  count = 1,
  color = '',
  highlightColor = '',
  circle = false,
  animate = true,
  className = ''
}) {
  const context = useContext(SkeletonContext);

  const elementWidth = width || context.width;
  const elementHeight = height || context.height;
  const elementOpacity = opacity || context.opacity;
  const shouldAnimate = animate || context.animate;
  const elementColor = color || context.color;
  const elementHighlightColor = highlightColor || context.highlightColor;
  const containerClassName = cn(
    styles.container,
    context.className,
    className,
    {
      [styles.circle]: circle
    }
  );

  if (!_isNumber(count) || count <= 0) return null;

  return Array(count)
    .fill(null)
    .map((_, index) => {
      return (
        <div
          key={index}
          className={containerClassName}
          data-animate={shouldAnimate}
          style={{
            width: elementWidth,
            height: elementHeight,
            opacity: elementOpacity,
            '--color': elementColor,
            '--highlight-color': elementHighlightColor
          }}
        />
      );
    });
}

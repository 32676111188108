import React from 'react';
import { ReactComponent as RRLogo } from 'images/rr-logo.svg';

const RR_LINK = 'https://www.returnrabbit.com';

export default function LogoStamp() {
  const openInNewTab = () => window.open(RR_LINK, '_blank').focus();

  return (
    <div className="logo-stamp-wrapper" onClick={openInNewTab}>
      <RRLogo />
    </div>
  );
}

import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';

import { connectStoreDetails } from 'utils/connectors/AppConnectors';
import { If } from 'utils';
import { ReactComponent as Logo } from 'images/rr-logo.svg';
import CovidDisclaimer from './CovidDisclaimer';

function PoweredByBar({ getStoreDetails, covidDisclaimer, className = '' }) {
  const storeDetails = getStoreDetails ? getStoreDetails() : {};
  const isVisible = _get(storeDetails, 'aspencxBranding.visible');
  let link = _get(storeDetails, 'aspencxBranding.link');

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className={cn('PoweredByBar', { [className]: !!className })}>
        <a
          className="PoweredByBar__title"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="font-weight-normal">Powered By</span>
          <Logo height={18} className="PoweredByBar__logo" />
        </a>
      </div>
      <If test={covidDisclaimer}>
        <CovidDisclaimer />
      </If>
    </>
  );
}

export default connectStoreDetails(PoweredByBar);

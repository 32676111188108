const STATUS = {
  SHIPPED: 'SHIPPED',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED'
};

const STEP_NUMBER_MAP = {
  [STATUS.SHIPPED]: 0,
  [STATUS.IN_TRANSIT]: 1,
  [STATUS.OUT_FOR_DELIVERY]: 2,
  [STATUS.DELIVERED]: 3
};

export { STATUS, STEP_NUMBER_MAP };

import { useContext, useMemo } from 'react';
import { Init } from '@return-rabbit/nexus';
import _get from 'lodash/get';

import { AuthContext } from 'context/AuthContext';

export default function useServiceable() {
  const { canCreateRequest } = useContext(AuthContext);
  const { serviceableItems } = useContext(Init.DataContext);

  const { isExchangeAllowed, isReturnAllowed } = useMemo(() => {
    return serviceableItems.reduce(
      (acc, item) => {
        const reasons = _get(item, 'reasons', []);
        reasons.forEach(reason => {
          const isReturnAllowed = _get(reason, 'allowsReturn', false);
          const isExchangeAllowed = _get(reason, 'allowsExchange', false);
          acc.isReturnAllowed = acc.isReturnAllowed || isReturnAllowed;
          acc.isExchangeAllowed = acc.isExchangeAllowed || isExchangeAllowed;
        });
        return acc;
      },
      { isExchangeAllowed: false, isReturnAllowed: false }
    );
  }, [serviceableItems]);

  let title = 'Select your item';
  if (isExchangeAllowed && isReturnAllowed) {
    title = 'What would you like to exchange or return?';
  } else if (isReturnAllowed) {
    title = 'What would you like to return?';
  } else if (isExchangeAllowed) {
    title = 'What would you like to exchange?';
  }

  return {
    canCreateRequest,
    isReturnAllowed,
    isExchangeAllowed,
    copies: { SERVICEABLE_ITEMS_HEADING: title }
  };
}
